import * as React from 'react';
import { IProductResponse, IWarehouseResponse } from 'components/Product/Results';
import { IProductLabels } from 'components/Product/Search';

interface IProps {
    product: IProductResponse;
    labels: IProductLabels;
}

const Availability: React.FunctionComponent<IProps> = (props) => {
    const { product, labels } = props;

    return (
        <React.Fragment>
            <div className="list-results__availability">
                {product.warehouses.length === 0 && <p>{labels.noStock}</p>}
                <table>
                    <tbody>
                        {product.warehouses.map((warehouse: IWarehouseResponse, i) => {
                            return (
                                <tr key={i}>
                                    <th>
                                        {product.warehouses.length === 0 ? 0 : warehouse.amount} {labels.pieceShort}
                                    </th>
                                    <td>{warehouse.name}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    );
};

export default Availability;
