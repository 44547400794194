import * as React from 'react';
import { useMutation } from 'redux-query-react';
import { useContext, useState } from 'react';
import Menu from 'ekaubamaja-ui/lib/Components/Menu';
import MenuItem from 'ekaubamaja-ui/lib/Components/MenuItem';
import { ICustomer } from '../../../interfaces/checkout/customer/ICustomer';
import LayoutSplit from 'ekaubamaja-ui/lib/Layouts/LayoutSplit';
import LayoutSplitColumn from 'ekaubamaja-ui/lib/Layouts/LayoutSplitColumn';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import { useTranslation } from 'react-i18next';
import RadioWithLabel from 'ekaubamaja-ui/lib/Components/RadioWithLabel';
import { useEffect } from 'react';
import { IPaymentMethodResponse } from '../../../interfaces/payment/IPaymentMethodResponse';
import { ResponseStatusEnum } from '../../../enums/ResponseStatus';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import { empty } from '../../../helpers/empty';
import { IPaymentMethodExtraData } from '../../../interfaces/payment/IPaymentMethodExtraData';
import { useRef } from 'react';
import { MutableRefObject } from 'react';
import { ICart } from '../../../interfaces/checkout/cart/cartType';
import { IShippingMethod } from '../../../interfaces/checkout/shipping/IShippingMethod';
import { IShippingAddress } from '../../../interfaces/checkout/address/IShippingInformationData';
import RedirectAbsolute, {IRedirectProps} from "components/Checkout/components/Redirect/RedirectAbsolute";
import useOverlays from "components/Checkout/components/overlay/Overlay";
import {CartContext} from "components/Checkout/components/checkout/Content";
import ExistingAddresses from "components/Checkout/components/address/existingAddresses";
import {postRequest} from "components/Checkout/data/requests/postRequest";
import HandleMethod, {PaymentMethodEnum} from "components/Checkout/components/checkout/Payment/Methods/MethodHandler";
import BusinessAddressForm from "components/Checkout/components/checkout/Address/BusinessAddressForm";
import ExistingAddressForm from "components/Checkout/components/checkout/Address/ExistingAddressForm";
import NewAddressOverlay from "components/Checkout/components/address/NewAddressOverlay";
import PersonalIdForm from "components/Checkout/components/checkout/Payment/PersonalIdForm";
import Agreements from "components/Checkout/components/checkout/Payment/Agreements";
import Subscription from "components/Checkout/components/checkout/Payment/Subscription";
import Banklinks from "components/Checkout/components/checkout/Payment/Banklinks";
import LoadingOverlay from "components/Checkout/components/checkout/Overlay/LoadingOverlay";
import {
    sortPaymentMethods
} from "components/Checkout/components/checkout/Payment/Helpers/paymentMethodsLayoutSortHelper";
import SelectedAddressToPostAddress from "components/Checkout/components/address/selectedAddressToPostAddress";

export interface IPaymentOnClickInput {
    paymentMethodExtraData?: IPaymentMethodExtraData;
    redirect?: IRedirectProps;
    rawMethodData?: any;
}

export interface IPaymentMethodProps {
    method: IPaymentMethodResponse;
    clientType: CLIENT_TYPE;
    paymentMethodExtraData: MutableRefObject<IPaymentMethodExtraData | undefined> | undefined;
    onClick: (input: IPaymentOnClickInput) => void;
    cart: ICart | undefined;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
    shippingMethod: IShippingMethod | undefined;
}

interface IProps {
    customer: ICustomer | null;
    paymentMethods: IPaymentMethodResponse[] | null;
    email: string | undefined;
    hasMedicine: boolean;
    cart: ICart | undefined;
    selectedMethod: IShippingMethod | undefined;
    selectedAddress: IShippingAddress | null;
}

export interface ITerms {
    id: string;
    accepted: boolean;
}

export enum CLIENT_TYPE {
    CLIENT_BUSINESS = 'business',
    CLIENT_REGULAR = 'client',
}

const COUNSELING_STATUS_HAS_NOT_ANSWERED = 0;
const COUNSELING_STATUS_HAS_BEEN_COUNSELED = 1;
const COUNSELING_STATUS_DOES_NOT_WANT = 2;

const PaymentBlock = (props: IProps) => {
    const { t } = useTranslation();
    const {openOverlay, closeOverlay} = useOverlays();
    const {customer, paymentMethods, email, hasMedicine, cart} = props;
    const allowQuery = useRef(false);
    const [updateState, setUpdateState] = useState(false);
    const [renderPersonalCode, setRenderPersonalCode] = useState<boolean>(!!window.isPersonalCodeEnabled);
    const [personalCodeCallback, setPersonalCodeCallback] = useState<({callbackMethod: () => void}) | undefined>(undefined);
    const [personalIdSet, setPersonalIdSet] = useState(false);
    const cartContext = useContext(CartContext);
    const [redirectUrl, setRedirectUrl] = useState<IRedirectProps | undefined>();
    const [methodCode, setMethodCode] = useState<string>();
    const [methodName, setMethodName] = useState<string>();
    const [selectedAddress, selectAddress] = useState<any>();
    const [errors, setErrors] = useState(false);
    const [businessFormErrors, setBusinessFormErrors] = useState(0);
    const [clientType, setClientType] = useState(CLIENT_TYPE.CLIENT_REGULAR);
    const [terms, setTerms] = useState<ITerms[]>(window.agreements?.totalRecords > 0 ? window.agreements.items.map(item => ({
        id: item.agreement_id,
        accepted: false,
    })) : []);
    const [newAddress, selectNewAddress] = useState();
    const paymentMethodRenderers: React.Component<any, any>[] = [];
    const defaultPaymentMethodRenderers: React.Component<any, any>[] = [];
    const verticalPaymentMethodRenderers: React.Component<any, any>[] = [];
    const rawData = useRef();
    const paymentMethodExtraData = useRef<Partial<IPaymentMethodExtraData>>();

    const [councellingStatus, setCouncellingStatus] = useState(COUNSELING_STATUS_HAS_NOT_ANSWERED);
    const addresses = ExistingAddresses(true, selectedAddress, selectAddress, null, customer, null).filter((address) => address.isBusiness);

    const [{isFinished, status}, postData] = useMutation(
        (data, url) => postRequest({type: 'response', url, data, useStoreCode: true})
    );
    const getExtensionAttributes = () => {
        const risksAwareness =
            councellingStatus === COUNSELING_STATUS_DOES_NOT_WANT ? 'im_aware_of_risks_myself' :
                councellingStatus === COUNSELING_STATUS_HAS_BEEN_COUNSELED ? 'advised_by_pharmacist' :
                    undefined;
        return {
            risks_awareness: risksAwareness,
            agreement_ids: terms.filter(item => item.accepted).map(item => item.id),
        };
    };
    const setAllowQuery = (state: boolean) => {
        allowQuery.current = state;
        setUpdateState(!updateState);
    };

    const assignMethodToComponent = (
        method: IPaymentMethodResponse,
        index: number,
        array: IPaymentMethodResponse[],
        isFreePaymentMethod: IPaymentMethodResponse | undefined,
        MethodComponent,
        layoutType?: string
    ) => {
        if (isFreePaymentMethod) {
            return;
        }
        const onClick = (input: IPaymentOnClickInput) => {
            // ensure personalCode is rendered through custom payment component, callback is defined
            // this will allow for method not to validate personal code if it is not needed
            // this will not affect window.isPersonalCodeEnabled behavior
            if (!!personalCodeCallback) {
                setRenderPersonalCode(false);
            }
            rawData.current = input.rawMethodData;
            setMethodCode(method.code);
            setMethodName(input?.rawMethodData?.name);
            paymentMethodExtraData.current = input.paymentMethodExtraData;
            setRedirectUrl(input.redirect);
            window.dispatchEvent(new CustomEvent('business-address-save'));
            window.dispatchEvent(new CustomEvent('subscription-save'));

            if (clientType !== CLIENT_TYPE.CLIENT_BUSINESS || (selectedAddress && !selectedAddress.isNew)) {
                setAllowQuery(true);
            }
        };

        MethodComponent = HandleMethod({
            method,
            clientType,
            paymentMethodExtraData,
            onClick,
            cart,
            email,
            extensionAttributes: getExtensionAttributes,
            setAllowQuery,
            shippingMethod: props.selectedMethod,
        });

        if (MethodComponent) {
            if (layoutType === 'default' && !defaultPaymentMethodRenderers.includes(MethodComponent)) {
                defaultPaymentMethodRenderers.push(MethodComponent);
            } else if (layoutType === 'vertical' && !verticalPaymentMethodRenderers.includes(MethodComponent)) {
                verticalPaymentMethodRenderers.push(MethodComponent);
            }

            if (!paymentMethodRenderers.includes(MethodComponent)) {
                paymentMethodRenderers.push(MethodComponent);
            }
        }
    };

    if (!paymentMethodRenderers.length) {
        const isFreePaymentMethod = paymentMethods?.find((method) => method.code === PaymentMethodEnum.free);

        let MethodComponent;

        // free payment method is returned only if grandTotal is <=0, if presents show only free payment method
        if (isFreePaymentMethod) {
            const onClick = (input: IPaymentOnClickInput) => {
                rawData.current = input.rawMethodData;
                setMethodCode(isFreePaymentMethod.code);
                paymentMethodExtraData.current = input.paymentMethodExtraData;
                setRedirectUrl(input.redirect);
                window.dispatchEvent(new CustomEvent('business-address-save'));
                window.dispatchEvent(new CustomEvent('subscription-save'));
                if (clientType !== CLIENT_TYPE.CLIENT_BUSINESS || (selectedAddress && !selectedAddress.isNew)) {
                    setAllowQuery(true);
                }
            };
            MethodComponent = HandleMethod({
                method: isFreePaymentMethod,
                clientType,
                paymentMethodExtraData,
                onClick,
                cart,
                email,
                extensionAttributes: getExtensionAttributes,
                setAllowQuery,
                shippingMethod: props.selectedMethod,
            });
            defaultPaymentMethodRenderers.push(MethodComponent);
            paymentMethodRenderers.push(MethodComponent);
        }

        if (!empty(window.detailedDefaultSortEnabled)) {
            const [defaultLayoutPayments, verticalLayoutPayments] = sortPaymentMethods(paymentMethods, window.paymentLogos);

            defaultLayoutPayments.forEach((method: IPaymentMethodResponse, index: number, array: IPaymentMethodResponse[]) => {
                assignMethodToComponent(method, index, array, isFreePaymentMethod, MethodComponent, 'default');
            });

            verticalLayoutPayments.forEach((method: IPaymentMethodResponse, index: number, array: IPaymentMethodResponse[]) => {
                assignMethodToComponent(method, index, array, isFreePaymentMethod, MethodComponent, 'vertical');
            });
        }

        paymentMethods?.forEach(
            (method: IPaymentMethodResponse, index, array) => {
                assignMethodToComponent(method, index, array, isFreePaymentMethod, MethodComponent);
            }
        );
    }

    const queryAction = () => {
        if (allowQuery.current) {
            let hasErrors = !!terms.find(term => !term.accepted) || (hasMedicine && councellingStatus === COUNSELING_STATUS_HAS_NOT_ANSWERED);

            if (renderPersonalCode && clientType !== CLIENT_TYPE.CLIENT_BUSINESS && !personalIdSet) {
                hasErrors = true;
            }

            if (clientType === CLIENT_TYPE.CLIENT_BUSINESS && businessFormErrors > 0) {
                hasErrors = true;
            }

            if (methodCode) {
                setErrors(hasErrors);
            }
            if (!hasErrors && methodCode) {
                openOverlay('payment-loading-overlay', true);
                setAllowQuery(false);
                let address;
                if (selectedAddress && clientType === CLIENT_TYPE.CLIENT_BUSINESS) {
                    address = SelectedAddressToPostAddress(selectedAddress);
                }

                const addressInformation = {
                    ...paymentMethodExtraData.current?.extraParams,
                    email,
                    billing_address: typeof address !== 'undefined' ? address : undefined,
                    paymentMethod: {
                        method: methodCode,
                        extension_attributes: getExtensionAttributes(),
                        additional_data: {...paymentMethodExtraData.current?.additionalData},
                    },
                };

                window.dispatchEvent(new CustomEvent('checkout-step-proceed', {detail: {
                    action: 'add-payment-info',
                    method: { methodCode, methodName },
                    items: cartContext.cartItems,
                    customer: cartContext.customer,
                }}));

                const url = paymentMethodExtraData.current?.paymentVerificationUrl ||
                    (isLoggedIn ? 'carts/mine/payment-information' : `guest-carts/${window.quoteIdMask}/payment-information`);
                postData({
                    ...addressInformation,
                }, url).then((response) => {
                    const responseSuccessful = response && response.status === ResponseStatusEnum.ok;
                    if (responseSuccessful) {
                        window.dispatchEvent(
                            new CustomEvent('payment-response-receive', {
                                detail: {
                                    methodCode,
                                    paymentResponse: response.body,
                                    rawData: rawData.current,
                                    setRedirectUrl,
                                    customer,
                                },
                            })
                        );
                    }

                    if (!responseSuccessful && response?.body?.message) {
                        closeOverlay('payment-loading-overlay', false);
                        setMethodCode(undefined);
                        setAllowQuery(true);

                        Toaster.addToast({
                            intent: 'danger',
                            text: response.body.message,
                            asHtml: true,
                        });
                    }
                    if (response.body && response.body.code === 455) {
                        setRedirectUrl({to: `checkout/cart`});
                    }
                });
            } else {
                setMethodCode(undefined);
            }
        }
    };
    useEffect(queryAction);

    useEffect(() => {
        if (isFinished && redirectUrl && !empty(redirectUrl.to)) {
            if (status === 400) {
                setTimeout(() => RedirectAbsolute({to: `checkout`}), 2000);
            } else {
                RedirectAbsolute(redirectUrl);
            }
        }
        if (selectedAddress && selectedAddress.isNew) {
            selectNewAddress(selectedAddress);
        }
    });
    const addressSaveAction = () => {
        setMethodCode(undefined);
        setRedirectUrl(undefined);
        window.dispatchEvent(new CustomEvent('business-address-save'));
    };

    const newAddressForm = (
        <BusinessAddressForm
            proceedAction={() => setAllowQuery(true)}
            selectedAddress={newAddress}
            customer={customer}
            selectAddress={selectAddress}
            setErrorCount={setBusinessFormErrors}
        />
    );

    const effectPersonalCodeNeeded = (e) => {
        if (e.detail && e.detail.callbackMethod) {
            setPersonalCodeCallback(e.detail);
            setRenderPersonalCode(true);
        }
    };

    useEffect(() => {
        window.addEventListener('personalCodeNeeded', effectPersonalCodeNeeded);
        return function cleanup() {
            window.removeEventListener('personalCodeNeeded', effectPersonalCodeNeeded);
        };
    }, []);

    return (
        <LayoutSplit>
            {window.isBusinessEnabled ? (
            <LayoutSplitColumn>
                <Menu layout="inline">
                    <MenuItem
                        text={t('checkout.Private customer')}
                        active={clientType === CLIENT_TYPE.CLIENT_REGULAR}
                        onClick={() => setClientType(CLIENT_TYPE.CLIENT_REGULAR)}
                    />
                    <MenuItem
                        text={t('checkout.Business customer')}
                        active={clientType === CLIENT_TYPE.CLIENT_BUSINESS}
                        onClick={() => setClientType(CLIENT_TYPE.CLIENT_BUSINESS)}
                    />
                </Menu>
                {clientType === CLIENT_TYPE.CLIENT_BUSINESS && (
                    <React.Fragment>
                        {window.moneyOrdersAllowedForBusiness !== undefined && (
                            !window.moneyOrdersAllowedForBusiness[PaymentMethodEnum.einvoice]
                            || !window.moneyOrdersAllowedForBusiness[PaymentMethodEnum.checkmo]
                        ) && (
                            <React.Fragment>
                                {(!!window.moneyOrdersAllowedForLogged && !isLoggedIn) && (
                                    <p>
                                        {t('checkout.To use invoice payment method')} <a href={window.LOGIN_URL}>{t('checkout.please sign in')}</a>
                                    </p>
                                )}
                                <p>
                                    {t('checkout.Some payment methods are disabled')}
                                </p>
                            </React.Fragment>
                        )}
                        {customer && addresses && ((addresses.length === 1 && !addresses.find(address => address.isNew)) || addresses.length > 1) ? (
                            <React.Fragment>
                                <ExistingAddressForm
                                    proceedAction={() => setAllowQuery(true)}
                                    selectedAddress={selectedAddress}
                                    addresses={addresses}
                                    showButton={false}
                                    title={t('checkout.Please select the company address and hit the Update button')}
                                />
                                <NewAddressOverlay
                                    title={t('checkout.Add a business address')}
                                    newAddressForm={newAddressForm}
                                    proceedAction={() => addressSaveAction()}
                                />
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {newAddressForm}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </LayoutSplitColumn>
            ) : <LayoutSplitColumn/>}
            <LayoutSplitColumn>
                {renderPersonalCode
                    && empty(window.isPersonalCodeBottomLocated) && clientType !== CLIENT_TYPE.CLIENT_BUSINESS && (
                    <PersonalIdForm
                        selectedAddress={props.selectedAddress}
                        setPersonalIdSet={setPersonalIdSet}
                        errors={errors}
                        personalCodeCallback={personalCodeCallback}
                    />
                )}
                <Agreements terms={terms} setTerms={setTerms} errors={errors}/>
                {!!window.isSubscriptionEnabled && <Subscription customer={customer} email={email}/>}
                <LayoutForm layout="vertical">
                    {hasMedicine && (
                        <FormRow
                            required={true}
                            error={errors && councellingStatus === COUNSELING_STATUS_HAS_NOT_ANSWERED ? t('This is a required field') : ''}
                            label={(
                            <b>{t('checkout.labelCouncelling')}</b>
                        )}>
                            <RadioWithLabel
                                label={t('checkout.labelCouncellingOption1')}
                                checked={councellingStatus === COUNSELING_STATUS_HAS_BEEN_COUNSELED}
                                onChange={() => setCouncellingStatus(COUNSELING_STATUS_HAS_BEEN_COUNSELED)}
                            />
                            <RadioWithLabel
                                label={t('checkout.labelCouncellingOption2')}
                                checked={councellingStatus === COUNSELING_STATUS_DOES_NOT_WANT}
                                onChange={() => setCouncellingStatus(COUNSELING_STATUS_DOES_NOT_WANT)}
                            />
                        </FormRow>
                    )}
                    <FormRow
                        label={(
                            <b>{t('checkout.Payment Method:')}</b>
                        )}
                        description={verticalPaymentMethodRenderers.length > 0 ? null : t('checkout.You will be redirected back to us after payment')}
                    >
                        <Banklinks
                            defaultComponents={defaultPaymentMethodRenderers}
                            components={paymentMethodRenderers}
                        />
                    </FormRow>
                    {verticalPaymentMethodRenderers.length > 0 && (
                        <FormRow
                            label={(
                                <b>{t('checkout.Installment Payment')}</b>
                            )}
                            description={t('checkout.You will be redirected back to us after payment')}
                        >
                            <Banklinks
                                verticalComponents={verticalPaymentMethodRenderers}
                                components={paymentMethodRenderers}
                            />
                        </FormRow>
                    )}
                </LayoutForm>
                {renderPersonalCode
                    && !empty(window.isPersonalCodeBottomLocated) && clientType !== CLIENT_TYPE.CLIENT_BUSINESS && (
                    <PersonalIdForm
                        selectedAddress={props.selectedAddress}
                        setPersonalIdSet={setPersonalIdSet}
                        errors={errors}
                        personalCodeCallback={personalCodeCallback}
                    />
                )}
            </LayoutSplitColumn>
            <LoadingOverlay
                index={'payment-loading-overlay'}
            />
        </LayoutSplit>
    );
};

export default PaymentBlock;
