import * as React from 'react';
import { Route, Switch } from 'react-router';
import Error404 from '../screens/errors/404/404';
import { ContentLoading } from '../components/loading/ContentLoading';

const Home = React.lazy(() => import('../screens/home/Home'));

const PublicRouter = () => (
    <React.Suspense fallback={(
        <ContentLoading />
    )}>
        <Switch>
            <Route path="/" exact={true} component={Home}/>
            <Route path="/" component={Error404}/>
        </Switch>
    </React.Suspense>
);

export default PublicRouter;
