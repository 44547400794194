import * as React from 'react';

interface IProps {
    type: string;
    text: string;
    key: string | number;
}

const Message = (props: IProps) => {
    const { type, text, key } = props;
    const intent = `cart-callout intent-${type}`;
    return (
        <React.Fragment>
            {text && <div key={key} className={intent} dangerouslySetInnerHTML={{ __html: text }} />}
        </React.Fragment>
    );
};

export default Message;
