import { isNumber } from 'lodash';

export const parseErrorMessage = (errorMessageObject: { message: string, parameters?: string[] }) => {
    const expr = /([%])\w+/g;

    if (!errorMessageObject.parameters) {
        return errorMessageObject.message;
    }

    return errorMessageObject.message.replace(expr, (varName): string => {
        varName = varName.substr(1);
        let index = 0;

        if (isNumber(parseInt(varName))) {
            index = parseInt(varName);
            index--;
        }

        if (errorMessageObject.parameters && errorMessageObject.parameters.hasOwnProperty(index)) {
            return errorMessageObject.parameters[index];
        }

        return errorMessageObject.parameters?.shift() || '';
    });
};
