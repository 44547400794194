import { empty } from '../empty';

export enum VALIDATION_TYPES {
    REQUIRED = 'required',
    MIN_SIZE = 'minSize',
    MAX_SIZE = 'maxSize',
    NAME = 'name',
    PHONE = 'phone',
    NUMBER = 'number',
    EMAIL = 'email',
}

export interface IValidationRule {
    rule: VALIDATION_TYPES;
    skip?: boolean;
    value?: number;
    message?: string;
}
export interface IValidationItem {
    field: string;
    validations: IValidationRule[];
}
export interface IErrorField {
    field: string;
    message: string;
}

/**
 * Sample validationrules:
 * {
 *       field: AddressFields.lastname,
 *       validations: [
 *           {rule: VALIDATION_TYPES.REQUIRED, skip: true, message: 'This value is required'},
 *           {rule: VALIDATION_TYPES.NAME},
 *       ],
 *   },
 * {
 *       field: AddressFields.telephone,
 *       validations: [
 *           {rule: VALIDATION_TYPES.REQUIRED},
 *           {rule: VALIDATION_TYPES.PHONE},
 *       ],
 * },
 * REQUIRED indicates that field cannot be in any way empty (ie 0, null or undefined). '0' is atm allowed;
 * MIN_SIZE indicates the lowest (eq) amount of chars available. Must be a value over 0;
 * MAX_SIZE indicates the highest (eq) amount of chars available. Must be a value over 0;
 * @param items
 * @param attributes
 * @param t
 */
/* tslint:disable */
export const validate = (items: IValidationItem[], attributes: object, t): IErrorField[] => {
    const errors: IErrorField[] = [];
    items.forEach((item: IValidationItem) => {
        if (!empty(item.validations)) {
            item.validations.forEach((rule: IValidationRule) => {
                if (!empty(errors.find((error => error.field === item.field))) || rule.skip) {
                    return;
                }
                let message: string | undefined;

                if (rule.rule === VALIDATION_TYPES.REQUIRED && empty(attributes[item.field])) {
                    message = t('This is a required field');
                }
                if (rule.value && rule.rule  === VALIDATION_TYPES.MIN_SIZE) {
                    if (attributes[item.field].length < rule.value) {
                        message = t('Please enter at least {value} characters', {value: rule.value});
                    }
                }
                if (rule.value && rule.rule  === VALIDATION_TYPES.MAX_SIZE) {
                    if (attributes[item.field].length > rule.value) {
                        message = t('Please enter no more than {value} characters', {value: rule.value});
                    }
                }
                if (rule.rule === VALIDATION_TYPES.NAME) {
                    if (!/^[^#&@:.![\]()¤=<>1234567890""~;$^%{}?]{2,40}$/.test(attributes[item.field])) {
                        message = t('Illegal name, make sure the field does not contain any illegal symbols');
                    }
                }
                if (rule.rule === VALIDATION_TYPES.PHONE) {
                    if (!/^[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(attributes[item.field])) {
                        message = t('Invalid phone number');
                    }
                }
                if (rule.rule === VALIDATION_TYPES.NUMBER) {
                    if (!/^[0-9]*$/.test(attributes[item.field])) {
                        message = t('Only numbers are allowed');
                    }
                }
                if (rule.rule === VALIDATION_TYPES.EMAIL) {
                    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(attributes[item.field])) {
                        message = t('Invalid email address');
                    }
                }
                if (!empty(message)) {
                    if (!empty(rule.message)) {
                        message = rule.message;
                    }
                    errors.push({
                        field: item.field,
                        message: message || '',
                    });
                }
            });
        }
    });

    return errors;
};
