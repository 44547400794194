import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'redux-query-react';
import { useRef, useState } from 'react';

import { request } from 'data/requests/request';

import Cart from '../checkout/cart/Cart';
import FramePage from '../framePage/FramePage';
import RedirectAbsolute from '../Redirect/RedirectAbsolute';
import ShippingContent from '../checkout/Content';

import { IQuote } from '../../interfaces/checkout/quote/IQuote';
import { ResponseStatusEnum } from '../../enums/ResponseStatus';
import { isLoggedIn } from '../../helpers/customer/isLoggedIn';
import useOverlays from '../overlay/Overlay';
import CartOverlay from '../checkout/cart/CartOverlay';

import FrameCheckout from 'ekaubamaja-ui/lib/Frames/Checkout';
import LayoutSidebar from 'ekaubamaja-ui/lib/Layouts/LayoutSidebar';
import LayoutColumnPrimary from 'ekaubamaja-ui/lib/Layouts/LayoutSidebarPrimary';
import LayoutColumnSecondary from 'ekaubamaja-ui/lib/Layouts/LayoutSidebarSecondary';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import { useEffect } from 'react';
import MessageWidget from '../message/Widget';
import Callout from 'ekaubamaja-ui/lib/Components/Callout';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';

const mapStateToProps = (state) => {
    return {
        quote: state.entities.quote,
        quoteId: state.entities.quote?.id,
        customer: state.entities.quote?.customer,
    };
};

const FrameMain = () => {
    const {openOverlay} = useOverlays();

    const quoteRequest = useRequest<IQuote | null>(request({type: 'quote', url: isLoggedIn ? 'carts/mine' : `guest-carts/${window.quoteIdMask}`}));
    const history = useHistory();
    const { t } = useTranslation();
    const { customer, quote } = useSelector(mapStateToProps);
    const [hasMedicine, setHasMedicine] = useState(false);
    const [cart, setCart] = useState<any>();
    const shippingPrice = useRef(0.00);
    const [updateState, setUpdateState] = useState(false);
    const [shippingMethodMessage, setShippingMethodMessage] = useState('');

    const CartContent = (
        <Cart
            customer={customer}
            setHasMedicine={setHasMedicine}
            cart={cart}
            quote={quote}
            setCart={setCart}
            shippingPrice={shippingPrice}
        />
    );
    const updateShipping = (event) => {
        shippingPrice.current = parseFloat(event.detail?.discountPrice ?? event.detail?.price);
        setShippingMethodMessage(event.detail?.message);
        setUpdateState(!updateState);
    };

    useEffect(() => {
        window.addEventListener('shipping-additional-change', updateShipping);
        return function cleanup() {
            window.removeEventListener('shipping-additional-change', updateShipping);
        };
    });
    const quoteRequestFinished = quoteRequest && quoteRequest[0].isFinished;
    if (quoteRequestFinished && (
        quoteRequest[0].status !== ResponseStatusEnum.ok
        || (quote && quote.items_qty < 1)
    )) {
        return (<RedirectAbsolute to={'checkout'}/>);
    }

    return (
        <React.Fragment>
            {quoteRequestFinished && (
                <FrameCheckout
                    title={t('checkout.Checkout')}
                    login={window.isLoginEnabled && !customer?.firstname ? {
                        title: t('checkout.Sign In'),
                        href: `${window.LOGIN_URL}`,
                    } : undefined}
                    logoHref={window.API_URL}
                    logoURL={window.checkoutConfig?.logo_src}
                    wrapHeader={true}
                >
                        <React.Fragment>
                            <FramePage title={t('checkout.Checkout')}>
                                <CartOverlay CartContent={CartContent}/>
                                <Block>
                                    <MessageWidget/>
                                    {shippingMethodMessage && (
                                        <Callout intent={'danger'}>
                                            <RenderHTML
                                                html={shippingMethodMessage}
                                                nowrapper={true}
                                            />
                                        </Callout>
                                    )}
                                    <LayoutSidebar
                                        openSidebarLabel={t('checkout.View purchase summary')}
                                        openSidebar={(e) => {
                                            e.preventDefault();
                                            history.push({ hash: 'summary' });
                                            openOverlay('summary', true);
                                        }}
                                        openSidebarIcon="cart"
                                        openSidebarAddOn={`${cart ? cart.base_grand_total : 0} €`}
                                    >
                                        <LayoutColumnPrimary
                                            title={t('Checkout')}
                                            titleActions={[
                                                {
                                                    title: t('checkout.Return to Store'),
                                                    className: 'has-text-color-primary',
                                                    icon: 'arrow2-left',
                                                    type: 'anchor',
                                                    href: window.API_URL,
                                                    layout: 'link',
                                                },
                                            ]}
                                            >
                                            <ShippingContent
                                                quote={quote}
                                                cart={cart}
                                                hasMedicine={hasMedicine}
                                            />
                                        </LayoutColumnPrimary>
                                        <LayoutColumnSecondary
                                            title={t('checkout.Order Summary')}
                                            icon="cart"
                                        >
                                        {CartContent}
                                        </LayoutColumnSecondary>
                                    </LayoutSidebar>
                                </Block>
                            </FramePage>
                        </React.Fragment>
                </FrameCheckout>
            )}
        </React.Fragment>
    );
};

export default FrameMain;
