import * as React from 'react';
import {IAdditional, IAdditionalComponents} from "components/Checkout/components/checkout/Shipping/Method/Additional";
import ProceedButton from "components/Checkout/components/checkout/ProceedButton";

interface IProps {
    proceedAction: () => void;
    continueDisabled: boolean;
    setAdditionalData: (additionalData: IAdditional) => void;
    additionalComponents: IAdditionalComponents;
}

const Simple = (props: IProps) => {
    const {proceedAction, setAdditionalData, additionalComponents} = props;

    return (
        <React.Fragment>
            {additionalComponents.courierInfo && additionalComponents.courierInfo.component}
            <ProceedButton
                proceedAction={() => {
                    const additionalData: IAdditional = {
                        courier_info: additionalComponents.courierInfo.exportField,
                    };
                    setAdditionalData(additionalData);
                    proceedAction();
                }}
                disabled={props.continueDisabled}
            />
        </React.Fragment>
    );

};
export default Simple;
