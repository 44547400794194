import * as React from 'react';
import Toolbar from 'components/Product/Toolbar';
import ResultRow from 'components/Product/ResultRow';
import { IProductLabels } from 'components/Product/Search';
import { useState } from 'react';
import isLoggedIn from '../../helpers/auth/isLoggedIn';

export interface IProductResponse {
    quoteItemId?: number;
    buyAmount: string;
    sku: string;
    name: string;
    noDiscountPrice: number;
    noDiscountPriceWithVat: number;
    priceWithoutVat: number;
    priceWithVat: number;
    isReplacement: boolean;
    replacementFromSku?: string;
    is404: boolean;
    stockCommon: number;
    brandId?: string;
    warehouses: IWarehouseResponse[];
}

export interface IWarehouseResponse {
    name: string;
    amount: number;
}

export interface IResultsProps {
    products: IProductResponse[];
    labels: IProductLabels;
    currencyCode: string;
    isCheckout?: boolean;
    isWishlist?: boolean;
    quoteId?: string;
    setError?: (error: string) => void;
}

const Results: React.FunctionComponent<IResultsProps> = (props) => {
    const { products, labels, currencyCode, isCheckout, isWishlist, quoteId, setError } = props;
    const [useWithoutVat, setUseWithoutVat] = useState(isLoggedIn());
    const [deliveryDays, setDeliveryDays] = useState('');

    return (
        <React.Fragment>
            <Toolbar setDeliveryDays={setDeliveryDays} setUseWithoutVat={setUseWithoutVat} labels={labels.toolbar} />
            <ul className="list-results">
                {products.map((product: IProductResponse) => {
                    return (
                        <React.Fragment key={product.quoteItemId ? product.quoteItemId : product.sku}>
                            <ResultRow
                                isWishlist={isWishlist}
                                setError={setError}
                                isCheckout={isCheckout}
                                quoteId={quoteId}
                                deliveryDays={deliveryDays}
                                useWithoutVat={useWithoutVat}
                                labels={labels}
                                currencyCode={currencyCode}
                                product={product}
                            />
                        </React.Fragment>
                    );
                })}
            </ul>
        </React.Fragment>
    );
};

export default Results;
