import { request } from 'data/requests/request';
import { IProductResponse } from 'components/Product/Results';

const addToWishlistRequest = (product: IProductResponse) =>
    request({
        type: 'vehoAddWishlist',
        url: `vehowishlist/wishlist/add`,
        method: 'POST',
        data: product,
        notApi: true,
        absolute: false,
        force: true,
    });

export default addToWishlistRequest;
