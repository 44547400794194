import * as React from 'react';
import { IShippingAddress } from '../../interfaces/checkout/address/IShippingInformationData';

const validatePersonalCode = (input: string, selectedAddress: IShippingAddress | null) => {
    let regex = new RegExp('[^0-9]', 'g');

    if (selectedAddress
        && selectedAddress.countryId
        && window.personalCodeValidationRules
        && window.personalCodeValidationRules[selectedAddress.countryId]) {
        regex = new RegExp(window.personalCodeValidationRules[selectedAddress.countryId], 'g');
    }

    return input.replace(regex, '');
};

export default validatePersonalCode;
