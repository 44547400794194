import { ICustomer, IGuestAddress } from '../../../../interfaces/checkout/customer/ICustomer';
import { empty } from '../../../../helpers/empty';
import { getCustomerCustomAttribute } from '../../../../helpers/customer/getCutomerCustomAttribute';

export const BaseAddressDefaults = (customer: ICustomer): IGuestAddress => ({
    firstname: empty(customer) ? '' : customer.firstname || '',
    lastname: empty(customer) ? '' : customer.lastname || '',
    telephone: empty(customer) ? '' : getCustomerCustomAttribute('custom_phone', customer) || '',
    phoneCode: '',
    region_id: 0,
    city: '',
    country_id: '',
    street: [],
    postcode: '',
    saveInAddressBook: false,
    company: '',
    vat_id: '',
});
