import * as React from 'react';
import Cookies from 'js-cookie';
import Message from 'components/Messages/Message';

interface ICookieMessage {
    type: string;
    text: string;
}

const Messages = () => {
    const cookieMessages = Cookies.get('mage-messages');
    const messages: ICookieMessage[] = cookieMessages ? JSON.parse(Cookies.get('mage-messages')) : [];
    Cookies.set('mage-messages', []);
    return (
        <React.Fragment>
            {messages &&
                Array.isArray(messages) &&
                messages.map((message, key) => {
                    if (message.type === 'error') {
                        message.type = 'danger';
                    }
                    if (message.type === 'notice') {
                        message.type = 'warning';
                    }
                    return <Message type={message.type} text={message.text} key={key} />;
                })}
        </React.Fragment>
    );
};

export default Messages;
