import {applyMiddleware, compose, createStore} from 'redux';
import { QueriesState, queryMiddleware } from 'redux-query';

import thunk from 'redux-thunk';
import { exceptionHandler } from '../middlewares/exceptionHandler';
import rootReducer from './rootReducer';
import superagentInterface from 'redux-query-interface-superagent';

interface IStoreState {
    queries: QueriesState;
    entities: unknown;
}
export const getQueries = (state: IStoreState) => state.queries;
export const getEntities = (state: IStoreState) => state.entities;

declare global {
    interface Window {
        overlayOverlays: string[];
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
    }
}

export default function configureStore() {
    const queryMiddlewareHandler = queryMiddleware(superagentInterface, getQueries, getEntities);

    const devToolsCompose =
        Env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

    return createStore(
        rootReducer,
        {
            // template's initial state
            entities: {
                overlays: [...(window.overlayOverlays || [])],
            },
        },
        devToolsCompose(applyMiddleware(thunk, queryMiddlewareHandler, exceptionHandler)),
    );
}
