import { request } from 'data/requests/request';

const setWarehouseRequest = (code) =>
    request({
        type: 'vehoSetWarehouse',
        url: `vehowarehouse/warehouse/set`,
        method: 'POST',
        data: { code },
        notApi: true,
        absolute: false,
        force: true,
    });

export default setWarehouseRequest;
