import * as React from 'react';
import { ICartItem } from '../../../interfaces/checkout/cart/cartType';
import { IOptionItem } from '../../../interfaces/checkout/cart/IOptionItem';

export const getItemProductTypePrice = (item: ICartItem) => {
    const optionItems: IOptionItem = item.options ? JSON.parse(item.options) : {};
    if (optionItems.product_type) {
        const itemPrice = item.base_row_total_incl_tax;
        return { label: optionItems.product_type.label, value: itemPrice };
    }
    return {};
};
