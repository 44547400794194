import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';

const ComponentMapperRecaptcha = () => {
    const [stateToken, setStateToken] = useState<string>('');
    const myRef = useRef<HTMLInputElement>(null);
    const submitting = useRef<boolean>(false);
    useEffect(() => {
        if (myRef.current) {
            const form = myRef.current.closest('form');
            if (form) {
                if (stateToken) {
                    form?.submit();
                } else {
                    form.addEventListener('submit', (e) => {
                        e.preventDefault();
                        if (!submitting.current) {
                            submitting.current = true;
                            getCaptchaToken().then((responseToken) => {
                                setStateToken(responseToken);
                            });
                        }
                    });
                }
            }
        }
    });
    return <input ref={myRef} type={'hidden'} name="g-recaptcha-response" value={stateToken} />;
};
export default ComponentMapperRecaptcha;
