import * as React from 'react';
import { ICartItem } from '../../../interfaces/checkout/cart/cartType';
import { IOptionItem } from '../../../interfaces/checkout/cart/IOptionItem';
import { sum } from '../../../helpers/cart/sum';
import * as i18next from 'i18next';

export const hiDiscount = (item: ICartItem, t: i18next.TFunction): React.ReactNode => {
    const optionItems: IOptionItem = item.options ? JSON.parse(item.options) : {};
    if (optionItems.hiDiscount) {
        return (
            <span key={`${item.item_id}_hiDiscount`}>
                {t('checkout.Health Insurance Discount')} {sum(optionItems.hiDiscount?.value || '0')} €<br/>
            </span>
        );
    }
    return null;
};
