export enum AddressFields {
    email = 'email',
    firstname = 'firstname',
    lastname = 'lastname',
    telephone = 'telephone',
    region_id = 'region_id',
    city = 'city',
    street = 'street',
    postcode = 'postcode',
    vat_id = 'vat_id',
    company = 'company',
    fax = 'fax',
}
