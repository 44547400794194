export const getHeaders = (): { [key: string]: string } => {
    const headers: { [key: string]: string } = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    const token = window.jwt;
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    return headers;
};
