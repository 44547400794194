import * as React from 'react';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import CartSidebar from 'components/Cart/CartSidebar';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import Results, { IProductResponse } from 'components/Product/Results';
import { IProductLabels } from 'components/Product/Search';
import { CartType } from '../../enums/CartType';
import { ICreditLimitData } from 'components/Cart/Interfaces/ICreditLimitData';

export interface ICheckoutCartProps {
    cartType: string;
    config: {
        products: IProductResponse[];
        cartTotals: ICartTotals;
        unpaidInvoices?: number;
        unpaidInvoicesLabel?: string;
        cartContainer?: string;
        emptyMessage?: string;
        currencyCode: string;
        labels: IProductLabels;
        creditLimitData?: ICreditLimitData;
    };
}
interface CartResponseType {
    products: IProductResponse[];
    cartTotals: ICartTotals;
}

const CheckoutCart = (props: ICheckoutCartProps) => {
    let {
        products,
        cartTotals,
        cartContainer,
        emptyMessage,
        labels,
        currencyCode,
        creditLimitData,
        unpaidInvoices,
        unpaidInvoicesLabel,
    } = props.config;
    const { cartType } = props;
    const [state, setState] = useState({ products, cartTotals });
    const [{}, cartRequest] = useMutation(() => request({ type: 'cart', url: 'cart/cart/index', notApi: true }));
    const [error, setError] = useState<string>();

    const effect = useCallback(async () => {
        const response = await cartRequest();
        const cartResponse: CartResponseType = response.body;
        setState({
            products: cartResponse.products,
            cartTotals: cartResponse.cartTotals,
        });
    }, [cartRequest, state]);

    /**
     * @param props
     * @constructor
     */
    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    });
    return (
        <React.Fragment>
            {state.products.length !== 0 && (
                <CartSidebar
                    isCheckout={cartType === CartType.CHECKOUT}
                    isWishlist={cartType === CartType.WISHLIST}
                    cart={state.cartTotals}
                    cartContainer={cartContainer}
                    products={state.products}
                    labels={labels}
                    creditLimitData={creditLimitData}
                    unpaidInvoices={unpaidInvoices}
                    unpaidInvoicesLabel={unpaidInvoicesLabel}
                />
            )}
            <div className="layout-cart__main">
                {error && <div className="cart-callout intent-danger">{error}</div>}
                {state.products.length === 0 && emptyMessage && <div className="cart-callout">{emptyMessage}</div>}
                {state.products.length > 0 && (
                    <Results
                        setError={setError}
                        quoteId={state.cartTotals.quoteId}
                        isCheckout={cartType === CartType.CHECKOUT}
                        isWishlist={cartType === CartType.WISHLIST}
                        currencyCode={currencyCode}
                        labels={labels}
                        products={state.products}
                    />
                )}
            </div>
        </React.Fragment>
    );
};

export default CheckoutCart;
