import * as React from 'react';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import {IPaymentMethodProps} from "components/Checkout/components/checkout/Payment/PaymentBlock";
import SimplePayment from "components/Checkout/components/checkout/Payment/Methods/SimplePayment";

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
}

const FreePayment = (props: IProps) => {
    const {method, paymentMethodProps} = props;
    const redirect = {to: `checkout/onepage/success`};

    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => paymentMethodProps.onClick({redirect})}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default FreePayment;
