import { ICustomer } from '../../interfaces/checkout/customer/ICustomer';

export function getCustomerCustomAttribute (attributeName: string, customer: ICustomer | null = null) {
    if (customer && customer.custom_attributes) {
        const attribute = customer.custom_attributes.find((item) => item.attribute_code === attributeName);
        if (attribute) {
            return attribute.value;
        }
    }
    return 0;
}
