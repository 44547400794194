import { combineReducers } from 'redux';
import { entitiesReducer, queriesReducer } from 'redux-query';
import { OverlaysState } from 'data/overlays/interfaces';
import overlaysReducer from 'data/overlays/overlaysReducer';

export interface IStoreState {
    overlaysReducer: OverlaysState;
}

const combineRootReducer = combineReducers({
    entities: entitiesReducer,
    queries: queriesReducer,
    overlaysReducer,
});

export default combineRootReducer;
