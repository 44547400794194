import { request } from 'data/requests/request';

const productsRequest = (keywords: string) =>
    request({
        type: 'vehoProductRequest',
        url: `vehosearch/product/index?keywords=${keywords}`,
        method: 'GET',
        notApi: true,
        absolute: false,
        force: true,
    });

export default productsRequest;
