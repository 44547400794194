import * as React from 'react';
import { useMutation } from 'redux-query-react';
import { IRequestData, request } from 'data/requests/request';
import Summary from 'ekaubamaja-ui/lib/Applications/Checkout/Components/Summary';
import { ISummaryMeta, ISummaryProduct } from 'ekaubamaja-ui/lib/Applications/Checkout/Components/Summary/index';
import { ICustomer } from '../../../interfaces/checkout/customer/ICustomer';
import { useTranslation } from 'react-i18next';
import CustomerData from '../customer/CustomerData';
import { itemDiscountPercent } from '../cart/itemDiscountPercent';
import { useEffect, useRef, useState } from 'react';
import { getItemProductTypePrice } from '../cart/getItemProductTypePrice';
import { getItemShowExtraTerms } from '../cart/getItemShowExtraTerms';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import { MutableRefObject } from 'react';
import { hiDiscount } from '../cart/hiDiscount';
import { trhDiscount } from '../cart/trhDiscount';
import { sum } from '../../../helpers/cart/sum';
import { ContentLoading } from '../..//loading/ContentLoading';
import useErrorState from '../../error/Error';
import { IQuote } from '../../../interfaces/checkout/quote/IQuote';
import { ProductTypeEnum } from '../../../enums/productType/Types';

export interface IProps {
    customer: ICustomer | null;
    setHasMedicine: any;
    cart: any;
    shippingPrice: MutableRefObject<number>;
    setCart: any;
    quote: IQuote | null;
}

const PRODUCT_CAMPAIGN_SEGMENT = 'product_campaign_discount';
const GIFT_CARD_SEGMENT = 'gift_card_discount';
const HI_SEGMENT = 'health_insurance';
const TRH_SEGMENT = 'trh';

const Cart = (props: IProps) => {
    const { customer, setHasMedicine, cart, setCart, shippingPrice, quote } = props;
    const hasMedicineItem = useRef(false);
    const queryingCart = useRef(false);
    const { t } = useTranslation();
    const data: IRequestData = {type: 'cart', url: isLoggedIn ? 'carts/mine/totals' : `guest-carts/${window.quoteIdMask}/totals`};
    const [{isFinished, status}, cartRequest] = useMutation( () => (request(data)));
    const responseSuccessful = () => (!!cart && status === 200);
    const {setError} = useErrorState();
    const [updateState, setUpdateState] = useState(false);
    const metaDefault: ISummaryMeta[] = [];
    const cartMetaData = useRef(metaDefault);

    let medicineTotal = 0;
    let nonMedicineTotal = 0;
    let cartItems: ISummaryProduct[] = responseSuccessful() && cart.items.map((item, key) => {
        const sumAddOn: React.ReactNode[] = [];
        if (!hasMedicineItem.current && getItemShowExtraTerms(item)) {
            setHasMedicine(true);
        }

        if (!window.aMoneyData?.isMoneyUsed) {
            sumAddOn.push(itemDiscountPercent(item, t));
        }
        if (window.isTrhEnabled) {
            sumAddOn.push(hiDiscount(item, t));
            sumAddOn.push(trhDiscount(item, t));
        }
        const itemProductTypePrice = getItemProductTypePrice(item);
        if (itemProductTypePrice.label && itemProductTypePrice.value) {
            if (itemProductTypePrice.label === ProductTypeEnum.medicine) {
                medicineTotal += itemProductTypePrice.value;
                cart.medicineTotal = medicineTotal;
            } else {
                nonMedicineTotal += itemProductTypePrice.value;
                cart.nonMedicineTotal = nonMedicineTotal;
            }
        }
        const description = quote?.items?.find((quoteItem) => item.item_id === quoteItem.item_id)?.sku;

        return ({
            name: item.name,
            amount: item.qty,
            description,
            sum: `${sum(item.row_total_incl_tax)}&nbsp;€`,
            sumAddOn,
        });
    });
    if (!cartItems) {
        cartItems = [];
    }
    const getSegmentAttribute = (attributeName: string, usePartialSearch: boolean = false) => {
        if (responseSuccessful()) {
            const attribute = cart.total_segments.find((item) => item.code === attributeName);
            if (attribute) {
                return attribute;
            }

            if (usePartialSearch) {
                const attributes = cart.total_segments.filter((item) => {
                    return item.code.substr(0, attributeName.length) === attributeName;
                });

                if (attributes) {
                    return attributes;
                }
            }
        }
        return undefined;
    };

    const updateMeta = () => {
        const metaCart = cart;
        if (!metaCart || !responseSuccessful()) {
            return;
        }
        if (metaCart) {
            metaCart.base_grand_total = parseFloat((metaCart.base_grand_total - (cart?.shipping_incl_tax || 0)).toFixed(2));
            metaCart.base_grand_total = parseFloat((metaCart.base_grand_total + shippingPrice.current).toFixed(2));
            metaCart.shipping_incl_tax = shippingPrice.current;
        }
        let subtotal = metaCart ? metaCart.subtotal_incl_tax : 0;
        let hkSegment;
        let trhSegment;
        if (window.isTrhEnabled) {
            hkSegment = getSegmentAttribute(HI_SEGMENT);
            trhSegment = getSegmentAttribute(TRH_SEGMENT);
            if (hkSegment) {
                subtotal += hkSegment.value;
            }
            if (trhSegment) {
                subtotal += trhSegment.value;
            }
        }

        const meta: ISummaryMeta[] = [];
        meta.push({
            label: t('checkout.Cart Subtotal'),
            sum: `${sum(subtotal)}&nbsp;€`,
        });

        meta.push({
            label: t('checkout.Shipping'),
            sum: `${metaCart ? sum(metaCart.shipping_incl_tax) : 0}&nbsp;€`,
        });

        const aMoney = window.aMoneyData?.usedAmount;
        if (aMoney) {
            meta.push({
                label: t('checkout.Apotheka Money'),
                sum: `-${sum(aMoney)}&nbsp;€`,
            });
        }
        const productCampaignDiscount = getSegmentAttribute(PRODUCT_CAMPAIGN_SEGMENT, true);
        const giftCardDiscount = getSegmentAttribute(GIFT_CARD_SEGMENT);

        if (productCampaignDiscount) {
            productCampaignDiscount.forEach((discount) => {
                meta.push({
                    label: discount.title,
                    sum: `-${sum(discount.value)}&nbsp;€`,
                });
            });
        }

        if (giftCardDiscount) {
            meta.push({
                label: t('checkout.Gift Card Discount'),
                sum: `${sum(giftCardDiscount.value)}&nbsp;€`,
            });
        }

        if (cart.discount_amount) {
            meta.push({
                label: t('checkout.Cart discount'),
                sum: `${sum(cart.discount_amount)}&nbsp;€`,
            });
        }

        if (window.isTrhEnabled && hkSegment) {
            meta.push({
                label: t('checkout.Health Insurance Discount'),
                sum: `-${sum(hkSegment.value)}&nbsp;€`,
            });
        }

        if (window.isTrhEnabled && trhSegment) {
            meta.push({
                label: t('checkout.TRH Discount'),
                sum: `-${sum(trhSegment.value)}&nbsp;€`,
            });
        }

        if (metaCart.medicineTotal > 0 && metaCart.nonMedicineTotal > 0) {
            meta.push({
                label: t('checkout.Medicine products'),
                sum: `${sum(metaCart.medicineTotal)}&nbsp;€`,
            });

            meta.push({
                label: t('checkout.Non-medicine products'),
                sum: `${sum(metaCart.nonMedicineTotal)}&nbsp;€`,
            });
        }

        cartMetaData.current = meta;
    };
    updateMeta();

    const shippingDataSave = () => {
        updateCart(true);
    };

    const updateCart = (updateShippingPrice = false) => {
        if (!queryingCart.current) {
            queryingCart.current = true;
            cartRequest().then(response => {
                queryingCart.current = false;
                if (updateShippingPrice) {
                    shippingPrice.current = response.body.shipping_incl_tax;
                }
                setCart(response.body);
            });
        }
    };
    const updateShipping = (event) => {
        shippingPrice.current = parseFloat(event.detail?.discountPrice ?? event.detail?.price);
        updateMeta();
        setUpdateState(!updateState);
    };

    if ((!cart && !queryingCart.current) || (!isFinished && !!cart)) {
        updateCart();
    }
    useEffect(() => {
        if (isFinished && !!cart && !responseSuccessful()) {
            setError(true);
        }
        window.addEventListener('shipping-additional-change', updateShipping);
        window.addEventListener('shipping-additional-save', shippingDataSave);
        return function cleanup() {
            window.removeEventListener('shipping-additional-save', shippingDataSave);
            window.removeEventListener('shipping-additional-change', updateShipping);
        };
    });

    return (
        (isFinished && responseSuccessful()) ? (
            <React.Fragment>
                <Summary
                    title={`${t('checkout.Order')} ${responseSuccessful() ? cart.extension_attributes.order_id ?? '' : ''}`}
                    products={cartItems}
                    meta={cartMetaData.current}
                />
                <CustomerData cart={cart} customer={customer} aMoneyData={window.aMoneyData} currency={window.storeCurrencyCode!}/>
            </React.Fragment>
        ) : <ContentLoading/>
    );
};
export default Cart;
