import * as React from 'react';

import { useEffect, useState } from 'react';
import 'ekaubamaja-ui/lib/Applications/Checkout/Components/Banklinks/styles.scss';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow/index';
import { useTranslation } from 'react-i18next';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import { useSelector } from 'react-redux';
import { empty } from '../../../helpers/empty';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import { IShippingAddress } from '../../../interfaces/checkout/address/IShippingInformationData';
import validatePersonalCode from '../../../helpers/form/validatePersonalCode';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';

interface IProps {
    setPersonalIdSet: (isSet: boolean) => void;
    errors: boolean;
    selectedAddress: IShippingAddress | null;
    personalCodeCallback?: {callbackMethod: (contactId: string) => void};
}
const mapStateToProps = (state) => {
    return {
        personalCode: state.entities.personalCode,
    };
};
const PersonalIdForm = (props: IProps) => {
    const {t} = useTranslation();
    const [contactId, setContactId] = useState('');
    const url = (isLoggedIn ? 'carts/mine/personal-code' : `guest-carts/${window.quoteIdMask}/personal-code`);
    useRequest(request({type: 'personalCode', url}));
    const [{}, setRequest] = useMutation(() => request({method: 'POST', type: 'setPersonalCode', url, data: {personalCode: contactId}}));
    const { personalCode } = useSelector(mapStateToProps);

    const setContractIdChange = (personalCodeValue: string) => {
        setContactId(personalCodeValue);
        window.dispatchEvent(new CustomEvent('personalCodeChange', { detail: { personalCode: personalCodeValue } }));
    };

    useEffect(() => {
        if (!empty(personalCode)) {
            props.setPersonalIdSet(true);
        }
    }, [personalCode]);

    const saveContactId = (contactIdValue) => {
        setRequest().then(response => {
            if (response.body === true || response.body === 'true') {
                props.setPersonalIdSet(contactIdValue.length > 0 && true);
            } else {
                if (response.body.errorMessage) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: response.body.errorMessage,
                    });
                }
            }
        });
    };

    return (
        <React.Fragment>
            <FormRow label={t('checkout.Contract signer personal ID')} required={true} error={props.errors && empty(contactId) && t('This is a required field')}>
                <ControlInput
                    value={contactId}
                    autoFocus={!!props?.personalCodeCallback?.callbackMethod}
                    onChange={(e) => {
                        const result: string = validatePersonalCode(e.target.value, props.selectedAddress);
                        setContractIdChange(result);
                        setTimeout(() => {
                            saveContactId(result);
                        }, 500);
                    }}
                />
            </FormRow>
            <span dangerouslySetInnerHTML={{__html: t('I agree to the insurance Terms and Conditions')}}/>
            {props?.personalCodeCallback?.callbackMethod && contactId && (
                <React.Fragment>
                    <Buttons layout="vertical-wide">
                        <Button
                            intent={'primary'}
                            title={t('checkout.Pay')}
                            disabled={false}
                            onClick={() => props?.personalCodeCallback?.callbackMethod(contactId)}
                        />
                    </Buttons>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default PersonalIdForm;
