export const languages = [
    {
        value: 'en',
        label: 'English',
    },
    {
        value: 'fr',
        label: 'Français',
    },
];
