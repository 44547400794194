import { request } from 'data/requests/request';
import { IProductResponse } from 'components/Product/Results';

const addToCartRequest = (product: IProductResponse) =>
    request({
        type: 'vehoCartAdd',
        url: `vehocart/cart/add`,
        method: 'POST',
        data: product,
        notApi: true,
        absolute: false,
        force: true,
    });

export default addToCartRequest;
