import * as React from 'react';
import Icon from 'ekaubamaja-ui/lib/Components/Icon/index';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import Block from 'ekaubamaja-ui/lib/Components/Block/index';
import { useTranslation } from 'react-i18next';

/**
 * Used to show general error message on screen
 */
const ShowError = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Overlay
                isOpen={true}
                layout="dialog"
                doClose={(e) => {
                    e.preventDefault();
                }}
                dialogConfig={{
                    description: (
                        <React.Fragment>
                            <Block>
                                <div className={'error'}>
                                    <Icon width={60} height={60} kind="status-error"/>
                                    <p>{t('checkout.Having some problems')}</p>
                                </div>
                            </Block>
                        </React.Fragment>
                    ),
                    buttons: [
                        {
                            title: t('checkout.Reload page'),
                            intent: 'primary',
                            onClick: () => {
                                window.location.reload();
                            },
                        },
                    ],
                }}
            />
        </React.Fragment>
    );
};
export default ShowError;
