import { request } from 'data/requests/request';

const creditLimitGetRequest = () =>
    request({
        type: 'creditLimitGetRequest',
        url: `credit_limit/discount/get`,
        method: 'GET',
        notApi: true,
        absolute: false,
    });

export default creditLimitGetRequest;
