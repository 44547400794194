import * as React from 'react';
import { useMutation } from 'redux-query-react';
import { IPaymentMethodResponse } from '../../../../interfaces/payment/IPaymentMethodResponse';
import { isLoggedIn } from '../../../../helpers/customer/isLoggedIn';
import {IPaymentMethodProps} from "components/Checkout/components/checkout/Payment/PaymentBlock";
import {postRequest} from "components/Checkout/data/requests/postRequest";
import SimplePayment from "components/Checkout/components/checkout/Payment/Methods/SimplePayment";

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
    method: IPaymentMethodResponse;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes?: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
}

const MontonioSimple = (props: IProps) => {
    const {method, email, extensionAttributes, setAllowQuery} = props;
    const [{}, postData] = useMutation(
        data => postRequest(
            {
                type: 'setPayment',
                url: isLoggedIn ? 'carts/mine/set-payment-information' : `guest-carts/${window.quoteIdMask}/set-payment-information`,
                data,
                useStoreCode: true,
            }
        ));
    const redirect = {to: `checkout/onepage/success`};
    return (
        <SimplePayment
            method={method.code}
            key={method.code}
            onClick={() => {
                setAllowQuery(true);
                postData({
                    cartId: window.quoteIdMask,
                    email,
                    paymentMethod: {
                        method: method.code,
                        extension_attributes: extensionAttributes ? extensionAttributes() : [],
                    },
                }).then(response => {
                    setAllowQuery(false);
                    if (response.body === true || response.body === 'true') {
                        props.paymentMethodProps.onClick({redirect});
                    }
                });
            }}
            label={method.title}
            paymentMethodProps={props.paymentMethodProps}
        />
    );
};

export default MontonioSimple;
