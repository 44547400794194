import { QueryConfig, RequestBody } from 'redux-query';

export interface IRequestData {
    type: string;
    url: string;
    data?: RequestBody;
}

export const genericRequest = (data: IRequestData): QueryConfig => {
    return {
        url: data.url,
        options: {
            method: 'GET',
        },
        body: data.data,
        transform: (response: any) => ({
            [`${data.type}`]: response as any,
        }),
        update: {
            [`${data.type}`]: (prev: any, next: any) => {
                return next;
            },
        },
    };
};
