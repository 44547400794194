import * as React from 'react';
import { sortBy } from 'lodash';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect';
import FormRowGroup from 'ekaubamaja-ui/lib/Components/FormRowGroup';
import ButtonIcon from 'ekaubamaja-ui/lib/Components/ButtonIcon';
import { useTranslation } from 'react-i18next';
import LayoutFormColumn from 'ekaubamaja-ui/lib/Layouts/LayoutFormColumn';
import { useEffect, useState } from 'react';
import { IAddress, ICustomer } from '../../../interfaces/checkout/customer/ICustomer';
import { IRegionOptionListItem } from '../../../interfaces/checkout/address/IRegionOptionListItem';
import { isLoggedIn } from '../../../helpers/customer/isLoggedIn';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel/index';
import { empty } from '../../../helpers/empty';
import { IErrorField, IValidationItem, validate, VALIDATION_TYPES } from '../../../helpers/form/validate';
import { AddressFields } from '../../../enums/address/AddressFields';
import { useRef } from 'react';
import { getErrorForField } from '../../../helpers/form/getErrorForField';
import { useHistory } from 'react-router';
import { extractCodeFromPhone } from '../../../helpers/extractCodeFromPhone';
import useOverlays from "components/Checkout/components/overlay/Overlay";
import {ContactPhoneAreaItems} from "components/Checkout/components/checkout/Address/defaults/ContactPhoneAreaItems";
import {BaseAddressDefaults} from "components/Checkout/components/checkout/Address/defaults/BaseAddressDefaults";
import {Regions} from "components/Checkout/components/checkout/Address/defaults/Regions";
import {BaseAddressRules} from "components/Checkout/components/checkout/Address/defaults/BaseAddressRules";
import {dummyFields} from "components/Checkout/components/address/dummyFields";
import AdsSearch from "components/Checkout/components/address/AdsSearch";

export interface IProps {
    proceedAction: any | null;
    selectAddress: any;
    selectedAddress: IAddress | null;
    customer: ICustomer | null;
    setErrorCount: (errorCount: number) => void;
}
interface IFormFields {
    key: string;
    component: JSX.Element|string;
    sortOrder: number;
}

const BusinessAddressForm = (props) => {
    const { t } = useTranslation();
    const { proceedAction, selectAddress, selectedAddress, customer, setErrorCount } = props;
    const errorDefaults: IErrorField[] = [];
    const errors = useRef(errorDefaults);
    const history = useHistory();
    const [updateStateStatus, updateState] = useState(false);
    const {overlays, closeOverlay} = useOverlays();
    const overlayOpen = overlays.indexOf('add-address') !== -1;
    const contactPhoneAreaItems = ContactPhoneAreaItems();
    const addressDefaults = selectedAddress || {
        ...BaseAddressDefaults(customer),
        company: '',
        vat_id: '',
        fax: '',
    };

    const regions = Regions;
    const defaultRegion = regions.find(reg => reg.value === addressDefaults.region_id);
    const [region, setRegion] = useState(defaultRegion || regions[0]);

    const {phoneCode, telephone} = extractCodeFromPhone(addressDefaults.telephone, addressDefaults.phoneCode);
    addressDefaults.telephone = telephone;
    if (phoneCode) {
        addressDefaults.phoneCode = phoneCode;
    }

    const [fields, setFields] = useState(addressDefaults);

    const changeRegion = (regionItem: IRegionOptionListItem) => {
        setRegion(regionItem);
        setFields({
            ...fields,
            region_id: regionItem.value,
            country_id: regionItem.country_id,
        });
    };

    const validateForm = () => {
        let validationRules: IValidationItem[] = BaseAddressRules();
        validationRules.push({
            field: AddressFields.company,
            validations: [
                {rule: VALIDATION_TYPES.REQUIRED},
            ],
        });
        validationRules.push({
            field: AddressFields.vat_id,
            validations: [
                {rule: VALIDATION_TYPES.REQUIRED},
            ],
        });

        if (Env.IS_VAT_REQUIRED === 'yes') {
            validationRules.push({
                field: AddressFields.fax,
                validations: [
                    {rule: VALIDATION_TYPES.REQUIRED},
                ],
            });
        }

        if (window.checkoutConfig?.business_address_form?.disable_contact_info) {
            validationRules = validationRules.filter((field) => !['firstname', 'lastname', 'telephone'].includes(field.field));
        }

        errors.current = validate(validationRules, fields, t);
    };

    const getError = (field) => getErrorForField(field, errors.current);

    const effect = () => {
        validateForm();
        updateState(!updateStateStatus);
        const errorCount = errors.current.length;

        setErrorCount(errorCount);
        if (errorCount < 1) {
            if (overlayOpen) {
                history.goBack();
                closeOverlay();
            }
            const address = {...fields, isNew: 1};
            Object.entries(address).map(([field]) => {
                if (empty(address[field])) {
                    address[field] = dummyFields[field];
                }
            });
            selectAddress(address);
            proceedAction();
        }
    };
    const adsSave = (event) => {
        if (event.detail) {
            changeRegion(regions.find(reg => reg.value === event.detail.region_id));
            setFields({...fields, ...event.detail});
        }
    };

    const formFields: IFormFields[] = [
        {
            key: 'firstname',
            component: !window.checkoutConfig?.business_address_form?.disable_contact_info ? (
                <FormRow
                    label={t('checkout.Firstname')}
                    required={true}
                    error={getError(AddressFields.firstname)}
                >
                    <ControlInput
                        name={'firstname'}
                        value={fields.firstname}
                        autocomplete={true}
                        onChange={(e) => setFields({...fields, firstname: e.target.value})}
                    />
                </FormRow>
            ) : '',
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.firstname ?? 10,
        },
        {
            key: 'lastname',
            component: !window.checkoutConfig?.business_address_form?.disable_contact_info ? (
                <FormRow
                    label={t('checkout.Lastname')}
                    required={true}
                    error={getError(AddressFields.lastname)}
                >
                    <ControlInput
                        name={'lastname'}
                        value={fields.lastname}
                        autocomplete={true}
                        onChange={(e) => setFields({...fields, lastname: e.target.value})}
                    />
                </FormRow>
            ) : '',
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.lastname ?? 20,
        },
        {
            key: 'company',
            component: (
                <FormRow
                    label={t('checkout.Company')}
                    required={true}
                    error={getError(AddressFields.company)}
                >
                    <ControlInput
                        name={'company'}
                        value={fields.company}
                        autocomplete={true}
                        onChange={(e) => setFields({...fields, company: e.target.value})}
                    />
                </FormRow>
            ),
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.company ?? 30,
        },
        {
            key: 'ads_search',
            component: (window.inAddressConfiguration?.enabled ? <AdsSearch /> : ''),
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.ads_search ?? 40,
        },
        {
            key: 'region',
            component: (
                <FormRow
                    label={t('checkout.County')}
                    required={true}
                    error={getError(AddressFields.region_id)}
                >
                    <ControlSelect
                        disableSearch={true}
                        value={region}
                        onChange={(e) => changeRegion(e.target.value)}
                    >
                        {regions.map(regionOption => {
                            return (
                                <option key={regionOption.value} value={regionOption}>{regionOption.label}</option>
                            );
                        })}
                    </ControlSelect>
                </FormRow>
            ),
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.region ?? 50,
        },
        {
            key: 'street',
            component: (
                <FormRow
                    label={t('checkout.Address')}
                    required={true}
                    error={getError(AddressFields.street)}
                >
                    <ControlInput
                        name={'street'}
                        value={fields.street[0] || ''}
                        autocomplete={true}
                        onChange={(e) => setFields({...fields, street: [e.target.value]})}
                    />
                </FormRow>
            ),
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.street ?? 60,
        },
        {
            key: 'city',
            component: (
                <FormRow
                    label={t('checkout.City')}
                    required={true}
                    error={getError(AddressFields.city)}
                >
                    <ControlInput
                        name={'city'}
                        value={fields.city}
                        autocomplete={true}
                        onChange={(e) => setFields({...fields, city: e.target.value})}
                    />
                </FormRow>
            ),
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.city ?? 70,
        },
        {
            key: 'postcode',
            component: (
                <FormRow
                    label={t('checkout.Postcode')}
                    required={true}
                    error={getError(AddressFields.postcode)}
                >
                    <ControlInput
                        name={'postcode'}
                        value={fields.postcode}
                        autocomplete={true}
                        onChange={(e) => setFields({...fields, postcode: e.target.value})}
                    />
                </FormRow>
            ),
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.postcode ?? 80,
        },
        {
            key: 'telephone',
            component: !window.checkoutConfig?.business_address_form?.disable_contact_info ? (
                <FormRow
                    label={t('checkout.Mobile number')}
                    required={true}
                    error={getError(AddressFields.telephone)}
                >
                    <FormRowGroup>
                        <ControlSelect
                            value={fields.phoneCode}
                            onChange={(e) => setFields({...fields, phoneCode: e.target.value})}
                            size="small"
                        >
                            {contactPhoneAreaItems.map(item => (
                                <option key={item} value={item}>{item}</option>)
                            )}
                        </ControlSelect>
                        <ControlInput
                            name={'phone'}
                            value={fields.telephone}
                            autocomplete={true}
                            onChange={(e) => setFields({...fields, telephone: e.target.value})}
                        />
                        <ButtonIcon
                            icon={'question'}
                            intent="link"
                            tooltip={t('checkout.descriptionDeliveryConfirm')}
                        />
                    </FormRowGroup>
                </FormRow>
            ) : '',
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.telephone ?? 90,
        },
        {
            key: 'vat_id',
            component: (
                <FormRow
                    label={t('checkout.Vat id')}
                    required={true}
                    error={getError(AddressFields.vat_id)}
                >
                    <ControlInput
                        name={'vat_id'}
                        value={fields.vat_id}
                        autocomplete={true}
                        onChange={(e) => setFields({...fields, vat_id: e.target.value})}
                    />
                </FormRow>
            ),
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.vat_id ?? 100,
        },
        {
            key: 'fax',
            component: Env.ALLOW_VAT_CHECKOUT === 'yes' ? (
                <FormRow
                    label={t('checkout.Company VAT number')}
                    error={getError(AddressFields.fax)}
                    required={Env.IS_VAT_REQUIRED === 'yes'}
                >
                    <ControlInput
                        name={'fax'}
                        value={fields.fax}
                        autocomplete={true}
                        onChange={(e) => setFields({...fields, fax: e.target.value})}
                    />
                </FormRow>
            ) : '',
            sortOrder: window.checkoutConfig?.business_address_form?.fields_sort_order?.fax ?? 110,
        },
    ];
    const formFieldsSorted: IFormFields[] = sortBy(formFields, 'sortOrder');

    useEffect(() => {
        window.addEventListener('business-address-save', effect);
        window.addEventListener('ads-address-select', adsSave);
        return function cleanup() {
            window.removeEventListener('ads-address-select', adsSave);
            window.removeEventListener('business-address-save', effect);
        };
    });

    const setVatId = (value: string) => {
        setFields({...fields, vat_id: value});
        window.dispatchEvent(new CustomEvent('companyCodeChange', { detail: { code: value } }));
    };

    return (
        <React.Fragment>
            <LayoutForm layout="vertical">
                <LayoutFormColumn>
                    {formFieldsSorted.map((field) => (
                        <React.Fragment key={field.key}>
                            {field.component}
                        </React.Fragment>
                    ))}
                    {isLoggedIn && (
                        <React.Fragment>
                            <FormRow>
                                <CheckboxWithLabel
                                    label={(t('Save in address book'))}
                                    checked={!!fields.saveInAddressBook}
                                    onChange={() => setFields({...fields, saveInAddressBook: !fields.saveInAddressBook})}
                                />
                            </FormRow>
                        </React.Fragment>
                    )}
                </LayoutFormColumn>
            </LayoutForm>
        </React.Fragment>
    );
};

export default BusinessAddressForm;
