import 'custom-event-polyfill';

import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import configureStore from './helpers/reduxStore';
import { ThemeProvider } from 'react-jss';

import 'theme/scss/index.scss';
import { BrowserRouter } from 'react-router-dom';
import MainFrameWrapper from 'components/frames/FrameMain';
import componentMapper from './componentMapper';

declare global {
    interface Window {
        THEME: { [key: string]: any };
        API_URL: string;
        STORE_CODE: string;
        jwt: string;
        rootElement: string;
    }
}

export const store = configureStore();

const doc = document.documentElement;

if (doc) {
    doc.classList.remove('is-loading');
}

const roots = document.querySelectorAll('[data-component]');

Array.from(roots).map((root, index) => {
    const maybeComponent = root.getAttribute('data-component');
    const maybeContent = root.innerHTML;
    const maybeHydrate = root.getAttribute('data-hydrate');
    const maybeConfig = JSON.parse(root.getAttribute('data-config') || '{}');

    if (maybeComponent) {
        const mappedComponent = componentMapper(maybeComponent, maybeContent, maybeConfig, []);
        if (mappedComponent) {
            const component = (
                <Provider store={store}>
                    <ReduxQueryProvider queriesSelector={(state) => state.queries}>
                        <ThemeProvider theme={{ ...window.THEME }}>{mappedComponent}</ThemeProvider>
                    </ReduxQueryProvider>
                </Provider>
            );
            if (maybeHydrate) {
                hydrate(component, root);
            } else {
                render(component, root);
            }
        }
    }
});
render(
    <Provider store={store}>
        <ThemeProvider theme={{ ...window.THEME }}>
            <ReduxQueryProvider queriesSelector={(state) => state.queries}>
                <BrowserRouter>
                    <MainFrameWrapper />
                </BrowserRouter>
            </ReduxQueryProvider>
        </ThemeProvider>
    </Provider>,
    document.getElementById(window.rootElement),
);
if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}
