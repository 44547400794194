import { request } from 'data/requests/request';
import { IProductResponse } from 'components/Product/Results';

const alterWishlistRequest = (product: IProductResponse) =>
    request({
        type: 'vehoAlterWishlist',
        url: `vehowishlist/wishlist/alter`,
        method: 'POST',
        data: product,
        notApi: true,
        absolute: false,
        force: true,
    });

export default alterWishlistRequest;
