import { request } from 'data/requests/request';

const addressDeleteRequest = (addressId) =>
    request({
        type: 'response',
        url: 'address_delete/delete/index',
        method: 'POST',
        data: { addressId },
        notApi: true,
    });

export default addressDeleteRequest;
