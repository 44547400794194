import { find } from 'lodash';
import { languages } from 'data/languages';

export function validateLocale (locale: string[]) {
    const preferredLocales = ['en'];
    locale.map((l: string) => {
        const parsed = l.replace(/[-_][a-z]+$/i, '');
        if (parsed !== 'en' && find(languages, (r) => r.value === parsed)) {
            preferredLocales.push(parsed);
        }
    });
    return preferredLocales.length > 1 ? preferredLocales[1] : preferredLocales[0];
}
