import { includes, isEqual, pull } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export interface IComponentsOverlay extends Window {
    overlayScrollbarWidth: number;
    overlayOverlays: string[];
    overlaySavedScrollPos: number;
}

declare const window: IComponentsOverlay;

const doc = document.documentElement;

/**
 * Extends props with currently opened overlays array, scrollbarWidth, openOverlay and closeOerlay props. See source code for details.
 */
const useOverlays = () => {
    if (!window.overlayOverlays) {
        window.overlayOverlays = [];
    }
    if (!window.overlaySavedScrollPos) {
        window.overlaySavedScrollPos = 0;
    }
    const overlays = useRef(window.overlayOverlays ? [...window.overlayOverlays] : []);
    const [scrollbarWidth, setScrollbarWidth] = useState(window.overlayScrollbarWidth ? window.overlayScrollbarWidth : 0);
    const [savedScrollPos, setSavedScrollPos] = useState(window.overlaySavedScrollPos ? window.overlaySavedScrollPos : 0);
    const [requestStateChange, setRequestStateChange] = useState(false);

    const openingScrollbars = () => {
        if (doc) {
            let scrollwidth = window.innerWidth - doc.clientWidth;
            if (window.overlayScrollbarWidth && window.overlayScrollbarWidth > scrollwidth) {
                scrollwidth = window.overlayScrollbarWidth;
            }
            if (scrollwidth > 0) {
                window.overlayScrollbarWidth = scrollwidth;
                window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:sync'));
            }
        }
    };
    const closingScrollbars = () => {
        if (doc && !doc.classList.contains('overlayopen')) {
            window.overlayScrollbarWidth = 0;
            window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:sync'));
        }
    };

    const handlingSync = () => {
        if (!isEqual(overlays.current, window.overlayOverlays)) {
            overlays.current = ([...window.overlayOverlays]);
        }
        if (
            scrollbarWidth !== window.overlayScrollbarWidth ||
            savedScrollPos !== window.overlaySavedScrollPos
        ) {
            setScrollbarWidth(window.overlayScrollbarWidth);
            setSavedScrollPos(window.overlaySavedScrollPos);
        }
    };
    const handlingResize = () => {
        if (doc && overlays.current.length > 0) {
            const root = document.getElementById(window.rootElement || 'root');
            if (root) {
                root.style.height = doc.clientHeight + 'px';
                root.style.minHeight = doc.clientHeight + 'px';
            }
            doc.style.height = doc.clientHeight + 'px';
        }
    };

    const handleOpen = (name: string, asNew: boolean) => {
        if (includes(overlays.current, name)) {
            handleClose(name);
        } else {
            const scroller = document.getElementById(window.rootElement || 'root');
            if (doc) {
                const pos = doc.scrollTop || document.body.scrollTop;
                window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:open'));

                handlingResize();

                if (overlays.current.length === 0) {
                    // Scroll to top
                    window.scrollTo(0, 0);

                    // Clip window to size
                    doc.classList.add('overlayopen');

                    // Cache scroll position
                    window.overlaySavedScrollPos = pos;
                    setSavedScrollPos(pos);

                    window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:sync'));

                    window.requestAnimationFrame(() => {
                        if (scroller) {
                            scroller.scrollTop = pos;
                        }
                    });
                }
                const arr = asNew ? [...overlays.current] : [];
                arr.push(name);
                overlays.current = arr;
                window.overlayOverlays = arr;
                window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:sync'));
            }
        }
    };

    const handleClose = (name?: string, instant?: boolean, noScrollRestore?: boolean) => {
        let arr = [...overlays.current];
        if (doc) {
            if (arr.length > 0) {
                if (name === 'all') {
                    arr = [];
                } else if (name) {
                    pull(arr, name);
                } else {
                    arr.splice(-1, 1);
                }
                window.overlayOverlays = arr;
                window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:sync'));
                if (doc && window.overlayOverlays.length === 0) {
                    closeOverlayCleanup(false, noScrollRestore);
                }
            } else if (name === 'all') {
                closeOverlayCleanup(instant, noScrollRestore);
            }
        }
        setRequestStateChange(!requestStateChange);
    };

    const closeOverlayCleanup = (instant?: boolean, noScrollRestore?: boolean) => {
        if (doc) {
            doc.style.height = '';
            const root = document.getElementById(window.rootElement || 'root');
            if (root) {
                root.style.height = '';
                root.style.minHeight = '';
            }
            doc.classList.remove('overlayopen');
            if (!noScrollRestore && savedScrollPos > 0) {
                document.body.scrollTop = savedScrollPos;
                doc.scrollTop = savedScrollPos;
                window.requestAnimationFrame(() => {
                    document.body.scrollTop = savedScrollPos;
                    doc.scrollTop = savedScrollPos;
                });
            }
            if (instant) {
                window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:close'));
            } else {
                setTimeout(() => {
                    window.dispatchEvent(new CustomEvent('ekaubamaja:overlay:close'));
                }, 250);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('ekaubamaja:overlay:open', openingScrollbars);
        window.addEventListener('ekaubamaja:overlay:close', closingScrollbars);
        window.addEventListener('ekaubamaja:overlay:sync', handlingSync);
        window.addEventListener('resize', handlingResize);

        return function cleanup() {
            window.removeEventListener('ekaubamaja:overlay:open', openingScrollbars);
            window.removeEventListener('ekaubamaja:overlay:close', closingScrollbars);
            window.removeEventListener('ekaubamaja:overlay:sync', handlingSync);
            window.removeEventListener('resize', handlingResize);
        };
    });

    return ({
        overlays: overlays.current,
        openOverlay: handleOpen,
        closeOverlay: handleClose,
        scrollbarWidth,
    });
};
export default useOverlays;
