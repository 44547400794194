import { ResponseStatus } from '../../enums/ResponseStatus';

interface I422BodyType {
    field: string;
    message: string;
    path?: string;
}
interface IErrorBodyType {
    message: string;
}

const getErrorObjects = (messages: Record<string, unknown>[]) =>
    messages.map((message): unknown => {
        // default prop
        if (message.constraints) {
            return {
                [message.property as string]: Object.values(message.constraints as Record<string, unknown>).join(' '),
            };
        }

        // nested props

        // class-validator sets message property to numeric string
        // have to research and do changes in backend, maybe then there is no need to check isNaN
        if (isNaN(message.property as number)) {
            return {
                [message.property as number]: getErrorObjects(message.children as Record<string, unknown>[]),
            };
        }
        // errors have to be differentiated, so the value is added into the object
        if (message.value) {
            return {
                value: message.value,
                errors: Object.assign({}, ...getErrorObjects(message.children as Record<string, unknown>[])) as Record<
                    string,
                    unknown
                >[],
            };
        }
        // there is still no valid property or value
        return getErrorObjects(message.children as Record<string, unknown>[]);
    });

export const extractErrors = (result?: {
    status: ResponseStatus;
    body: I422BodyType[] | IErrorBodyType;
}): Record<string, string> | undefined => {
    if (!result) {
        return undefined;
    }
    const messages = result.body;
    if (result.status === ResponseStatus.formError) {
        if (Array.isArray(messages)) {
            const newData: Record<string, string> = {};
            messages.forEach((item) => (newData[`${item.path ? `${item.path}.` : ''}${item.field}`] = item.message));
            return newData;
        }
    }
    if (messages && !Array.isArray(messages) && messages.message) {
        return {
            ['error']: messages.message,
        };
    }
    return undefined;
};
