import * as React from 'react';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getItem, setItem } from '../../helpers/storage';
import isLoggedIn from '../../helpers/auth/isLoggedIn';

export interface ICartButtonProps {
    /* DOM to parse */
    dom: string;
    config: {
        href: string;
        src: string;
        label: string;
    };
}

const mapStateToProps = (state) => {
    return {
        grandTotal: state.entities.primitiveCart?.grandTotal,
        itemsCount: state.entities.primitiveCart?.itemsCount,
        totalWithoutTaxValue: state.entities.primitiveCart?.totalWithoutTaxValue,
    };
};

const CartButton: React.FunctionComponent<ICartButtonProps> = (props) => {
    const [{}, cartRequest] = useRequest(request({ type: 'primitiveCart', url: 'fast/cart/info', notApi: true }));
    let { grandTotal, itemsCount, totalWithoutTaxValue } = useSelector(mapStateToProps);
    const [total, setTotal] = useState(getItem('grandTotal'));
    const [totalWithoutTax, setTotalWithoutTax] = useState(getItem('totalWithoutTaxValue'));
    const [count, setCount] = useState(getItem('itemsCount'));
    const [useWithoutVat, setUseWithoutVat] = useState(isLoggedIn());

    useEffect(() => {
        setTotal(grandTotal);
        setCount(itemsCount);
        setTotalWithoutTax(totalWithoutTaxValue);
    }, [grandTotal, itemsCount, totalWithoutTaxValue]);

    const effect = useCallback(async () => {
        const response = await cartRequest();
        setTotal(response.body?.grandTotal);
        setCount(response.body?.itemsCount);
        setTotalWithoutTax(response.body?.totalWithoutTaxValue);
    }, [cartRequest]);

    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    }, [cartRequest]);

    const effectShowPrice = (e) => {
        setUseWithoutVat(e.detail.useWithoutVatPrice);
    };

    useEffect(() => {
        window.addEventListener('price-vat-show-change', effectShowPrice);
        return function cleanup() {
            window.removeEventListener('price-vat-show-change', effectShowPrice);
        };
    }, []);

    return (
        <a href={props.config.href || ''}>
            <span className="icon">
                <img src={props.config.src || ''} alt={props.config.label || ''} />
            </span>
            <span className="label">{props.config.label || ''}</span>
            <b className="amount">{useWithoutVat ? totalWithoutTax : total}</b>
            <b className="products">{count}</b>
        </a>
    );
};

export default CartButton;
