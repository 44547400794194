export function empty (variable: any) {
    return (
        typeof variable === 'undefined' ||
        variable === null ||
        variable === 0 ||
        variable === '' ||
        variable === undefined ||
        variable === '0' ||
        !variable ||
        (typeof variable === 'object' && empty(Object.values((variable as object)).find((val) => !empty(val)))) ||
        (Array.isArray(variable) && variable.length < 1)
    );
}
