import { request } from 'data/requests/request';

const customerDeleteRequest = () =>
    request({
        type: 'response',
        url: 'customer_delete/delete/index',
        method: 'POST',
        notApi: true,
    });

export default customerDeleteRequest;
