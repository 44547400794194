import * as React from 'react';
import { IToolbarLabels } from 'components/Product/Search';
import { useMutation, useRequest } from 'redux-query-react';
import warehousesRequest from 'components/Product/requests/warehousesRequest';
import { useSelector } from 'react-redux';
import warehouseCustomerRequest from 'components/Product/requests/warehouseCustomerRequest';
import { useEffect } from 'react';
import { empty } from '../../helpers/empty';
import setWarehouseRequest from 'components/Product/requests/setWarehouseRequest';
import isLoggedIn from '../../helpers/auth/isLoggedIn';

interface IVehoWarehouseResponse {
    warehousesResponse: IVehoWarehouse[];
}

interface IVehoWarehouse {
    code: string;
    name: string;
    deliveryDays: string;
}

interface IDefaultWarehouse {
    defaultWarehouseResponse: {
        code?: string;
    };
}

const mapStateToProps = (state): IVehoWarehouseResponse => {
    return {
        warehousesResponse: state.entities.vehoWarehouses,
    };
};

const mapStateToPropsDefaultWarehouse = (state): IDefaultWarehouse => {
    return {
        defaultWarehouseResponse: state.entities.vehoDefaultWarehouse,
    };
};

interface IProps {
    labels: IToolbarLabels;
    setUseWithoutVat: (value: boolean) => void;
    setDeliveryDays: (value: string) => void;
}

const Toolbar: React.FunctionComponent<IProps> = (props) => {
    const [{ isFinished }] = useRequest(warehousesRequest());
    const [{ isFinished: isFinishedCustomer }] = useRequest(warehouseCustomerRequest());
    const { warehousesResponse } = useSelector(mapStateToProps);
    const { defaultWarehouseResponse } = useSelector(mapStateToPropsDefaultWarehouse);

    const [{}, setWarehouseQuery] = useMutation((warehouse: IVehoWarehouse) => setWarehouseRequest(warehouse.code));

    const setDelivery = (warehouseCode?: string) => {
        let warehouse = warehousesResponse.find((warehouse: IVehoWarehouse) => warehouse.code === warehouseCode);

        if (!warehouse && warehousesResponse.length) {
            warehouse = warehousesResponse[0];
        }

        if (warehouse) {
            props.setDeliveryDays(warehouse.deliveryDays);
            setWarehouseQuery(warehouse);
        }
    };

    useEffect(() => {
        if (isFinished && isFinishedCustomer && !empty(warehousesResponse)) {
            setDelivery(defaultWarehouseResponse?.code);
        }
    }, [isFinishedCustomer, isFinished]);

    const setShowPricesVat = (e) => {
        const useWithoutVatPrice = parseInt(e.target.value) === 1;
        props.setUseWithoutVat(useWithoutVatPrice);
        window.dispatchEvent(
            new CustomEvent('price-vat-show-change', {
                detail: {
                    useWithoutVatPrice,
                },
            }),
        );
    };

    return (
        <React.Fragment>
            <div className="product-toolbar">
                <div className="group">
                    <label>
                        <span className="label">{props.labels.defaultWarehouse}</span>
                        {isFinished && isFinishedCustomer && (
                            <select
                                defaultValue={defaultWarehouseResponse?.code}
                                onChange={(e) => setDelivery(e.target.value)}
                            >
                                {warehousesResponse.map((warehouse: IVehoWarehouse, index: number) => {
                                    return (
                                        <option value={warehouse.code} key={index}>
                                            {warehouse.name}
                                        </option>
                                    );
                                })}
                            </select>
                        )}
                    </label>
                </div>
                <div className="group">
                    <label>
                        <span className="label">{props.labels.showPrices}</span>
                        <select defaultValue={isLoggedIn() ? 1 : 0} onChange={(e) => setShowPricesVat(e)}>
                            <option value={1}>{props.labels.withoutTax}</option>
                            <option value={0}>{props.labels.tax}</option>
                        </select>
                    </label>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Toolbar;
