import * as React from 'react';
import { useMutation } from 'redux-query-react';
import addressDeleteRequest from 'data/requests/customer/AddressDeleteRequest';
import DeleteBase from 'components/AccountDelete/DeleteBase';

export interface IAddressProps {
    config: {
        addressId: number;
        linkText: string;
        popUpTitle: string;
        popUpButtonConfirm: string;
        popUpButtonCancel: string;
    };
}

const AddressDelete: React.FunctionComponent<IAddressProps> = (props) => {
    const [{}, deleteRequest] = useMutation(() => addressDeleteRequest(props.config.addressId));

    return <DeleteBase deleteRequest={deleteRequest} config={props.config} />;
};

AddressDelete.displayName = 'Account delete';

export default AddressDelete;
