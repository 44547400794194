import { SupportedFileTypesEnum } from 'components/Product/enums/SupportedFileTypes';
import { empty } from '../../../helpers/empty';

export const getFileType = (file: File) => {
    let fileType: string = file.type;

    if (empty(fileType)) {
        // if file.type is empty take extension value
        const fileExt: string | undefined = file.name.split('.').pop();
        fileType = fileExt ?? '';
    }

    return fileType;
};

export const isSupported = (file: File) => {
    const supportedFileTypes: string[] = [SupportedFileTypesEnum.csv, SupportedFileTypesEnum.epc];

    return supportedFileTypes.includes(getFileType(file));
};
