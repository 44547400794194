import * as React from 'react';
import { ICustomer } from '../../../interfaces/checkout/customer/ICustomer';
import Callout from 'ekaubamaja-ui/lib/Components/Callout';
import { useTranslation } from 'react-i18next';
import { ICart } from '../../../interfaces/checkout/cart/cartType';
import { getIsAlpi } from '../../../helpers/customer/getIsAlpi';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import { IAmoneyData } from '../../../interfaces/checkout/amoney/IMoneyData';

export interface IProps {
    cart: ICart | null;
    customer: ICustomer | null;
    aMoneyData?: IAmoneyData;
    currency: string | null;
}

const CustomerData = (props: IProps) => {
    const { t } = useTranslation();
    const { cart, customer, aMoneyData, currency } = props;
    const savings = cart ? cart.extension_attributes?.total_discount : 0;
    const isAlpi = customer && getIsAlpi(customer);
    const hasAmoneyDiscount = aMoneyData && aMoneyData?.moneyEarned > 0;

    return (
        <React.Fragment>
            {isAlpi && (savings > 0 || hasAmoneyDiscount ) && (
                <Callout
                    intent={'default'}
                >
                    <p><b>{t('Apotheka customer benefits').toLocaleUpperCase()}</b></p>
                    <p>{t('Customer')}<br/><b>{customer?.firstname} {customer?.lastname}</b></p>
                    {savings > 0 && (
                        <p>{t('Immediate saving thanks to Apotheka customer card')} <b className="sum">{savings} {currency}</b>.</p>
                    )}
                    {hasAmoneyDiscount && (
                        <p>
                            <RenderHTML
                                html={t('aMoneyTranslation', {
                                    totalAMoney: aMoneyData?.moneyEarned,
                                    currency,
                                    interpolation: {escapeValue: false},
                                })}
                                nowrapper={true}
                            />
                        </p>
                    )}
                </Callout>
            )}
        </React.Fragment>
    );
};

export default CustomerData;
