import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import SiteMenu from 'ekaubamaja-ui/lib/Components/SiteMenu';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { updateStore } from 'data/overlays/updateStore';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';

export interface IMainMenuProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
}

const MainMenu: React.FunctionComponent<IMainMenuProps> = (props) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);
    return (
        <SiteMenu
            dom={props.dom}
            mobileMenuOpen={includes(openOverlays, 'mobilemenu')}
            openMobileMenu={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
                dispatch(openOverlay({ name: 'mobilemenu' }));
            }}
            closeMobileMenu={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
            }}
            target={props.config?.target || undefined}
        />
    );
};

MainMenu.displayName = 'MainMenu';

export default MainMenu;
