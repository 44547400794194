import { request } from 'data/requests/request';

const warehousesRequest = () =>
    request({
        type: 'vehoWarehouses',
        url: `vehowarehouse/warehouse/index`,
        method: 'GET',
        notApi: true,
        absolute: false,
        force: true,
    });

export default warehousesRequest;
