import * as React from 'react';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import { IProductResponse } from 'components/Product/Results';
import { useMutation } from 'redux-query-react';
import addToCartRequest from 'components/Product/requests/addToCartRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { IProductLabels } from 'components/Product/Search';
import { ICreditLimitData } from 'components/Cart/Interfaces/ICreditLimitData';
import LoyalityBlock from 'components/Cart/LoyalityBlock';
import removeWishlistRequest from 'components/Product/requests/removeWishlistRequest';
import { empty } from '../../helpers/empty';
import OverlayConfirm from 'components/overlay/OverlayConfirm';
import { useState } from 'react';

export interface IProps {
    isCheckout: boolean;
    isWishlist: boolean;
    cart: ICartTotals;
    cartContainer?: string;
    products: IProductResponse[];
    labels: IProductLabels;
    creditLimitData?: ICreditLimitData;
    unpaidInvoices?: number;
    unpaidInvoicesLabel?: string;
}

interface IResponsePromise {
    status: number;
    body: {
        message: string;
    };
}

const CartSidebar = (props: IProps) => {
    const {
        cart,
        cartContainer,
        isCheckout,
        isWishlist,
        products,
        labels,
        creditLimitData,
        unpaidInvoices,
        unpaidInvoicesLabel,
    } = props;
    if (!cart) {
        return <React.Fragment />;
    }
    const [showConfirm, setShowConfirm] = useState(false);
    const [{}, removeWishlistProduct] = useMutation((product) => removeWishlistRequest(product));

    const [{}, addToCartQuery] = useMutation((productItem) => addToCartRequest(productItem));

    const addAllToWishlist = (e) => {
        e.preventDefault();
        const errors: string[] = [];
        const promisesAdd: Promise<IResponsePromise>[] = [];
        products.forEach((product: IProductResponse) => {
            promisesAdd.push(addToCartQuery(product));
        });

        Promise.all(promisesAdd).then((responses) => {
            responses.forEach((response) => {
                if (response.status !== 200) {
                    errors.push(response.body.message);
                }
            });

            if (!errors.length) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
                Toaster.addToast({
                    intent: 'success',
                    text: labels.addedToWishlistSuccess,
                    asHtml: true,
                });
            } else {
                Toaster.addToast({
                    intent: 'danger',
                    text: errors.join('<br>'),
                    asHtml: true,
                });
            }
        });
    };

    const removeAllFromWishlistConfirm = (e) => {
        e.preventDefault();
        setShowConfirm(true);
    };

    const removeAllFromWishlist = () => {
        const promisesRemove: Promise<IResponsePromise>[] = [];
        const errors: string[] = [];
        products.forEach((product: IProductResponse) => {
            promisesRemove.push(removeWishlistProduct(product));
        });

        Promise.all(promisesRemove).then((responses) => {
            responses.forEach((response) => {
                if (response.status !== 200) {
                    errors.push(response.body.message);
                }
            });

            if (!errors.length) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
                Toaster.addToast({
                    intent: 'success',
                    text: labels.removeFromWishlistSuccess,
                    asHtml: true,
                });
            } else {
                Toaster.addToast({
                    intent: 'danger',
                    text: errors.join('<br>'),
                    asHtml: true,
                });
            }
        });
    };

    return (
        <div className={'layout-cart__sidebar'}>
            <div className={'cart-block primary'}>
                <h2>{cart.totalsLabel}</h2>
                <ul className={'cart-prices'}>
                    {cart.subtotal && (
                        <li>
                            <span className={'label'}>{cart.subtotal.label}:</span>
                            <span className={'value'}>{cart.subtotal.value}</span>
                        </li>
                    )}
                    <li>
                        <span className={'label'}>{cart.withoutTax.label}:</span>
                        <span className={'value'}>{cart.withoutTax.value}</span>
                    </li>
                    {cart.taxes &&
                        cart.taxes.map((item, key) => (
                            <li key={key}>
                                <span className={'label'}>{item.label}:</span>
                                <span className={'value'}>{item.value}</span>
                            </li>
                        ))}
                    <li className={'primary'}>
                        <span className={'label'}>{cart.grandTotal.label}:</span>
                        <span className={'value'}>{cart.grandTotal.value}</span>
                    </li>
                </ul>
                <ul className={'cart-buttons'}>
                    {isCheckout && (
                        <li>
                            {empty(unpaidInvoices) && (
                                <a className={'cart-button primary'} href={cart.checkoutUrl.value}>
                                    {cart.checkoutUrl.label}
                                </a>
                            )}
                            {!empty(unpaidInvoices) && (
                                <a className={'cart-button'} href="/customer/account/">
                                    {unpaidInvoicesLabel}
                                </a>
                            )}
                        </li>
                    )}
                    {isWishlist && (
                        <>
                            <li>
                                <a onClick={(e) => addAllToWishlist(e)} className={'cart-button primary'} href="">
                                    {cart.addAllToWishlistLabel}
                                </a>
                            </li>
                            <li>
                                <a onClick={(e) => removeAllFromWishlistConfirm(e)} className={'cart-button'} href="">
                                    {cart.removeAllFromWishlistLabel}
                                </a>
                            </li>
                        </>
                    )}
                    {cart.returnUrl && (
                        <li>
                            <a className={'cart-button'} href={cart.returnUrl.value}>
                                {cart.returnUrl.label}
                            </a>
                        </li>
                    )}
                </ul>
                {cartContainer && <RenderHTML nowrapper={true} html={cartContainer} />}
            </div>
            {creditLimitData && <LoyalityBlock config={creditLimitData} grandTotal={cart.grandTotal?.raw} />}
            {showConfirm && (
                <OverlayConfirm
                    title={labels.confirmTitle}
                    description={labels.confirmDescription}
                    buttonCancel={labels.confirmButtonCancel}
                    buttonOk={labels.confirmButtonOk}
                    onAccept={() => removeAllFromWishlist()}
                    onClose={() => setShowConfirm(false)}
                />
            )}
        </div>
    );
};

export default CartSidebar;
