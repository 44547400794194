import 'custom-event-polyfill';
import { ThemeProvider } from 'react-jss';

import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxQueryProvider } from 'redux-query-react';
import LocaleRouter from './routers/localeRouter';
import configureStore from '../../helpers/reduxStore';

export const store = configureStore();

declare const window: {
    THEME: { [key: string]: any };
    rootElement: string;
};

const CheckoutProvider = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={{ ...window.THEME }}>
                <ReduxQueryProvider queriesSelector={(state) => state.queries}>
                    <BrowserRouter>
                        <LocaleRouter />
                    </BrowserRouter>
                </ReduxQueryProvider>
            </ThemeProvider>
        </Provider>
    );
}

export default CheckoutProvider;
