import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const i18n = (translations) => {
    i18next
        .use(initReactI18next)
        .init({
            resources: {...translations},
            // resources: {...{ en: enTranslations }},
            ns: ['checkout'],
            defaultNS: 'checkout',
            debug: false,
            interpolation: {
                escapeValue: false,
            },
            nsSeparator: '.',
            parseMissingKeyHandler: (key: string) => {
                // todo :: currently strips the missing namespace only
                // if you are debugging, then add a wrapper for <span color="red"> or something similar
                const replacedKey = key.replace(/^.*\./, '');
                return Env.TRANSLATION_KEYS_ENABLED === 'yes' ? key : replacedKey;
            },
        })
    ;
    return i18next;
};

export default i18n;
