export enum ResponseStatus {
    badRequest = 400,
    formError = 422,
    created = 201,
    conflict = 409,
    forbidden = 403,
    internalServerError = 500,
    notFound = 404,
    ok = 200,
    unAuthorized = 401,
}
