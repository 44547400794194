import * as React from 'react';
import customerDeleteRequest from 'data/requests/customer/CustomerDeleteRequest';
import { useMutation } from 'redux-query-react';
import DeleteBase from 'components/AccountDelete/DeleteBase';

export interface ICustomerDeleteProps {
    config: {
        linkText: string;
        popUpTitle: string;
        popUpButtonConfirm: string;
        popUpButtonCancel: string;
    };
}

const AccountDelete: React.FunctionComponent<ICustomerDeleteProps> = (props) => {
    const [{}, deleteRequest] = useMutation(() => customerDeleteRequest());

    return <DeleteBase deleteRequest={deleteRequest} config={props.config} />;
};

AccountDelete.displayName = 'Account delete';

export default AccountDelete;
