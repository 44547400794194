import { request } from 'data/requests/request';

const setCustomerWarehouseRequest = (code: string) =>
    request({
        type: 'vehoSetWarehouse',
        url: `vehowarehouse/warehouse/setCustomer`,
        method: 'POST',
        data: { code },
        notApi: true,
        absolute: false,
        force: true,
    });

export default setCustomerWarehouseRequest;
