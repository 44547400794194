import { IGuestAddress } from '../../interfaces/checkout/customer/ICustomer';

export const baseDummyFields = {
    firstname: '-',
    lastname: '-',
    telephone: '+123456',
    phoneCode: '',
    city: '-',
    street: ['-'],
    postcode: '00000',
    company: '-',
    vat_id: '-',
};
export const dummyFields: IGuestAddress = {
    ...baseDummyFields,
    ...window.locationDefaults,
};
