import * as React from 'react';
import {CLIENT_TYPE, IPaymentMethodProps} from "components/Checkout/components/checkout/Payment/PaymentBlock";
import Empty from "components/Checkout/components/empty/Empty";
import MontonioSimple from "components/Checkout/components/checkout/Payment/Methods/MontonioSimple";
import MontonioPayment from "components/Checkout/components/checkout/Payment/Methods/MontonioPayment";
import Checkmo from "components/Checkout/components/checkout/Payment/Methods/Checkmo";
import {isLoggedIn} from "components/Checkout/helpers/customer/isLoggedIn";
import FreePayment from "components/Checkout/components/checkout/Payment/Methods/FreePayment";

export enum PaymentMethodEnum {
    makecommerce = 'makecommerce',
    checkmo = 'checkmo',
    cashondelivery = 'cashondelivery',
    einvoice = 'einvoice',
    montonio = 'montonio',
    montonio_payments = 'montonio_payments',
    montonio_payments_grouped = 'montonio_payments_grouped',
    montonio_payments_card = 'montonio_payments_card',
    free = 'free',
    banktransfer = 'banktransfer', // resurs bank related method
}
const checkMethodCodeExceptions = (methodCode: string) => {
    if (methodCode.startsWith(PaymentMethodEnum.montonio)) {
        if (methodCode.includes(PaymentMethodEnum.montonio_payments_card) ) {
            methodCode = PaymentMethodEnum.montonio_payments_card;
        } else {
            methodCode = PaymentMethodEnum.montonio;
        }
    }
    return methodCode;
};

const HandleMethod = (props: IPaymentMethodProps): React.Component => {
    let methodComponent;
    const method = props.method;
    let methodCode = method.code;
    const ignorePayments = [
        PaymentMethodEnum.banktransfer,
    ];

    if (ignorePayments.includes(methodCode as PaymentMethodEnum)) {
        methodComponent = <Empty/>;
        return methodComponent;
    }
    methodCode = checkMethodCodeExceptions(methodCode);

    switch (methodCode) {
        case PaymentMethodEnum.montonio_payments_card:
            methodComponent = (
                <MontonioSimple
                    key={method.code}
                    method={method}
                    paymentMethodProps={props}
                    email={props.email}
                    extensionAttributes={props.extensionAttributes}
                    setAllowQuery={props.setAllowQuery}
                />
            );
            break;
        case PaymentMethodEnum.montonio:
        case PaymentMethodEnum.montonio_payments:
        case PaymentMethodEnum.montonio_payments_grouped:
            methodComponent = (
                <MontonioPayment
                    key={method.code}
                    method={method}
                    paymentMethodProps={props}
                    email={props.email}
                    extensionAttributes={props.extensionAttributes}
                    setAllowQuery={props.setAllowQuery}
                />
            );
            break;
        case PaymentMethodEnum.checkmo:
        case PaymentMethodEnum.cashondelivery:
        case PaymentMethodEnum.einvoice:
            if (
                (props.clientType === CLIENT_TYPE.CLIENT_BUSINESS && window.moneyOrdersAllowedForBusiness !== undefined)
                || (window.allowCheckMoAll !== undefined && (props.clientType === CLIENT_TYPE.CLIENT_BUSINESS || window.allowCheckMoAll))
                || methodCode === PaymentMethodEnum.cashondelivery
            ) {
                methodComponent = (
                    <Checkmo
                        key={method.code}
                        method={method.code}
                        title={method.title}
                        paymentMethodProps={props}
                        disabled={
                            methodCode !== PaymentMethodEnum.cashondelivery
                            && (
                                (
                                    window.moneyOrdersAllowedForBusiness !== undefined
                                    && !window.moneyOrdersAllowedForBusiness[methodCode]
                                    && props.clientType === CLIENT_TYPE.CLIENT_BUSINESS
                                ) || (
                                    window.allowCheckMoAll !== undefined
                                    && !window.allowCheckMoAll && !isLoggedIn
                                )
                            )
                        }
                    />
                );
            }
            break;
        case PaymentMethodEnum.free:
            methodComponent = <FreePayment key={method.code} method={method} paymentMethodProps={props} email={props.email}/>;
            break;
        default:
            // tslint:disable-next-line:no-console
            console.error(`Invalid payment method (TODO) "${method.code}"`);
            methodComponent = <Empty/>;
            break;
    }
    return methodComponent;
};

export default HandleMethod;
